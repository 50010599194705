body {
    font-family: Montserrat;
    background-color: #0e1a3c;
    color: #fff;
    position: relative;
}

.page-container {
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
}

.btn:hover {
    color: #3369ff;
}

.theme-container {
    display: flex;
    column-gap: 30px;
}

    .theme-container .first-container {
        width: 280px;
        /* min-width: 280px;
        max-width: 280px; */
    }

        .theme-container .first-container .logo {
            cursor: pointer;
        }

            .theme-container .first-container .logo img {
                width: 130px;
            }

    .theme-container .second-container {
        width: calc(100% - 310px);
    }

h3 {
    font-size: 16px;
    font-family: Montserrat;
}
/* ///header/// */
/*-----------------------------------------------------------------------------------------------------------------------*/
#header-v3 {
    background-color: transparent;
    padding: 50px 0px;
}

@media (min-width: 991px) {
    #header-v3 .container {
        width: 95%;
        max-width: unset;
    }

    .container-theme-width {
        width: 95%;
        max-width: unset;
    }

    #header-v3 .header-search-box {
        border-radius: 10px 0 0 10px;
    }

        /*#header-v3 .header-search-box input {
            min-width: 250px;
        }*/
}

#header-v3 .form-group {
    margin-bottom: 0px;
}

#header-v3 .header-search-box input {
    background-color: inherit;
    border-left: 1px solid hsla(0,0%,79.6%,.14901960784313725);
    border-top: 1px solid hsla(0,0%,79.6%,.14901960784313725);
    border-bottom: 1px solid hsla(0,0%,79.6%,.14901960784313725);
    border-right: none;
    color: #fff;
    font-size: 12px;
    padding: 0 20px;
    width: 100%;
    height: 35px;
    font-weight: 700;
    font-family: Montserrat;
    margin-right: 1px;
    border-radius: 10px 0 0 10px;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff !important;
    opacity: 1; /* Firefox */
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff !important;
}

input::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff !important;
}

.form-control:focus {
    background: transparent !important;
}

#header-v3 .header-search-box .input-group {
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%,rgba(51,105,255,.2) 0,hsla(0,0%,100%,.0447917) 77.08%,hsla(0,0%,100%,0) 100%);
    background-blend-mode: overlay,normal;
    -webkit-backdrop-filter: blur(6.07811px);
    backdrop-filter: blur(6.07811px);
    color: #fff;
    font-weight: 300;
    font-family: Montserrat;
    border-radius: 10px 0 0 10px;
}

#header-v3 .header-search-box .input-group-text {
    background-color: inherit;
    border-right: 1px solid hsla(0,0%,79.6%,.14901960784313725);
    border-top: 1px solid hsla(0,0%,79.6%,.14901960784313725);
    border-bottom: 1px solid hsla(0,0%,79.6%,.14901960784313725);
    border-left: none;
    border-radius: 0 10px 10px 0;
}

#header-v3 .header-search-box .header-search-dropdown {
    position: absolute;
    width: fit-content;
    background: rgb(0 0 0 / 70%);
    font-weight: bold;
    border-radius: 10px;
    text-align: left;
    padding: 10px;
    line-height: 1.7;
    max-height: 200px;
    overflow-y: scroll;
    white-space: nowrap;
    z-index: 999;
}

    #header-v3 .header-search-box .header-search-dropdown::-webkit-scrollbar {
        display: none;
    }

    #header-v3 .header-search-box .header-search-dropdown .list-inline li {
        display: inline-block;
        cursor: pointer;
    }

#header-v3 .header-search-box .input-group-text .header-search-icon {
    cursor: pointer;
}

.header-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login-btn, .register-btn {
    box-shadow: 0 4px 7px #00000040;
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
    padding: 0px 10px;
    color: #fff;
    font-size: 14px;
    text-transform: unset;
    cursor: pointer;
    min-width: 125px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-btn {
    background: linear-gradient(180deg,#3369ff,rgba(0,0,0,.5) 133.33%);
    border: 1px solid #3369FF;
}

.register-btn {
    background: linear-gradient(180deg,#ff334b,rgba(0,0,0,.5) 173.08%);
    border: 1px solid #ff3333;
}

.header-right .second {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.lang-toggle {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.5) 0%, rgba(254, 94, 171, 0.05) 100%);
    box-shadow: 0 4px 7px rgba(0,0,0,.3);
    border-radius: 8px;
    color: rgba(235,235,245,.6);
    font-size: 12px;
    padding: 7px;
    line-height: 1;
    margin-bottom: 5px;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    width: auto;
    color: #fff;
    border-radius: 100px;
    column-gap: 5px;
    margin-bottom: 0px;
    cursor: pointer;
    height: 35px;
}

.lang-country-flag img {
    border: 0px;
    /*  height: auto;
  width: auto;*/
}

.lang-name-short {
    padding: 0px;
}

.lang-dropdown {
    background-color: #31376e;
    box-shadow: 0 4px 7px rgba(0,0,0,.3);
    border-radius: 8px;
    color: rgba(235,235,245,.6);
}

    .lang-dropdown .child-lang > div {
        padding: 0 8px;
        border-right: 1px solid rgba(235,235,245,.4);
        font-size: 10px;
        margin: 5px 0;
        font-weight: 400;
        cursor: pointer;
    }

        .lang-dropdown .child-lang > div:first-child {
            margin-left: 0px;
            padding-left: 0px;
        }

        .lang-dropdown .child-lang > div:last-child {
            margin-right: 0px;
            border-right: 0px;
        }
/*-----------------------------------------------------------------------------------------------------------------------*/
/* ///end header/// */
/* ///home/// */
.banner-slick {
    position: relative;
}

    .banner-slick .slick-dots {
        transform: rotate(90deg);
        right: 0px;
        display: inline-block !important;
        width: auto;
        top: 48%;
        /* transform: translate(0,-50%); */
        bottom: unset;
    }

.slick-dots li {
    background: transparent;
    position: relative;
    border: 1px solid #898F97;
    padding: 7px;
}

    .slick-dots li:after {
        content: ' ';
        background: #898F97;
        position: absolute;
        width: 70%;
        height: 70%;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        /* z-index: -1; */
        border-radius: 100px;
    }

    .slick-dots li.slick-active {
        background: transparent;
        position: relative;
        border: 1px solid #FFD928;
        padding: 7px;
    }

        .slick-dots li.slick-active:after {
            content: ' ';
            background: #FFD928;
            position: absolute;
            width: 70%;
            height: 70%;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            /* z-index: -1; */
            border-radius: 100px;
        }

    .slick-dots li button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 4;
    }

.banner-items {
    padding: 0px 10px;
}

    .banner-items .banner-items-image {
        width: 100%;
        border-radius: 20px;
    }

.banner-slick {
    margin: 0px -10px;
}

.home-announcement {
    display: flex;
    align-items: center;
    column-gap: 15px;
    padding: 0px 30px;
    font-size: 14px;
    /* font-weight: 300; */
}

.promo-banner-slider .slick-slide img {
    max-width: calc(100% - 20px);
    height: 100px;
    opacity: 0.5;
}

.promo-banner-slider .slick-slide.slick-current img {
    height: 125px;
    opacity: 1;
}

.promo-banner-slider .slick-track {
    display: flex;
    align-items: center;
    margin-left: 10px;
    justify-content: center;
    /* width: 100% !important; */
}

.promo-banner-slider .slick-slide {
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

@media (max-width: 900px) {
    .promo-banner-slider .slick-slide img {
        max-width: calc(100% - 25px);
        height: 90px;
    }

    .promo-banner-slider .slick-slide.slick-current img {
        height: 110px;
    }
}

@media (max-width: 800px) {
    .promo-banner-slider .slick-slide img {
        max-width: calc(100% - 30px);
        height: 90px;
    }

    .promo-banner-slider .slick-slide.slick-current img {
        height: 100px;
    }
}

.title-themed {
    position: relative;
    padding-left: 30px;
}

    .title-themed:before {
        content: ' ';
        background: #3369FF;
        border-radius: 0px 10px 10px 0px;
        width: 5px;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .title-themed h4 {
        font-size: 24px;
        margin-bottom: 10px;
        font-family: "Montserrat"
    }

    .title-themed p {
        font-size: 14px;
        color: #898F97;
        margin-bottom: 0px;
    }

.home-categories .home-top-categories {
    display: flex;
    column-gap: 30px;
}

    .home-categories .home-top-categories > div {
        width: 33.33%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

.after-home-category {
    margin-top: 30px;
}

.home-categories .home-top-categories > div > div {
    position: relative;
    opacity: .6;
    cursor: pointer;
}

.home-categories .category-active {
    border-radius: 10px;
    opacity: 1 !important;
}

.home-categories .home-game-category-label-left {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 20px;
    left: 30px
}

.home-categories .home-game-category-label-left-bottom {
    position: absolute;
    top: 80%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 20px;
    left: 30px
}

.home-categories .home-game-category-label-right {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 20px;
    right: 30px
}

.home-categories .home-game-category-label-right-bottom {
    position: absolute;
    top: 80%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 20px;
    right: 30px
}

.hr-normal {
    background: hsla(0, 0%, 100%, 0.24);
    width: 100%;
    height: 1px;
    margin: 40px 0px
}

.hr-left, .hr-right {
    background: hsla(0, 0%, 100%, 0.24);
    width: 60%;
    max-width: 500px;
    height: 1px;
    margin: 48px 0px
}

.hr-right {
    margin-left: auto;
    margin-right: 0px;
}

.hr-left {
    margin-left: 0px;
    margin-right: auto;
}

.home-provider-parent {
    display: grid;
    grid-template-rows: auto;
    grid-auto-flow: column;
    grid-auto-columns: 300px;
    grid-gap: 10px;
}

    .home-provider-parent .home-provider-items {
        flex: 1 0 31%; /* Adjust the width as needed, leaving some space for margin, padding, or other styling */
        margin: 1%;
        cursor: pointer;
    }

.home-provider-container {
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
}

    .home-provider-container::-webkit-scrollbar-track {
        /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
        background-color: transparent;
        border-radius: 100px;
    }

    .home-provider-container::-webkit-scrollbar {
        height: 6px !important;
        background-color: transparent;
        border-radius: 100px;
    }

    .home-provider-container::-webkit-scrollbar-thumb {
        background-color: #D9D9D980;
        border-radius: 100px;
    }

.gamelist-title {
    font-size: 11px;
    text-align: center;
    max-width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gamelist-slick {
    margin: 0px -10px;
}

.gamelist-items {
    padding: 0px 10px;
}

.pupular-pointer {
    /* width: 200px; */
    border-radius: 3px;
    width: 20px;
    height: 18px;
    position: relative;
    background: #FF334B;
    font-size: 11px;
    font-family: "poppins-black";
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
}

    .pupular-pointer:before {
        content: "";
        position: absolute;
        right: -8px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 9px solid #FF334B;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
    }

.gamelist-img-container {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}

    .gamelist-img-container .tag {
        position: absolute;
        top: 0px;
        right: -100px;
        /* z-index: 998; */
        z-index: 1;
    }

.gamelist-img-inner-shadow {
    /* background: linear-gradient(180deg, rgba(17, 4, 42, 0) 0%, rgba(16, 24, 45, 0.72) 68.75%, #10182D 100%);*/
    height: 56px;
    width: 100%;
    bottom: 0px;
    position: absolute;
}

.gamelist-provider {
    font-size: 8px;
    font-family: "poppins-medium";
    bottom: 7px;
    position: absolute;
    width: 100%;
    text-align: center;
}

.popular-game-wrapper .tag {
    position: absolute;
    top: -15px;
    left: -5px;
    /* z-index: 998; */
    z-index: 1;
}

.tag-number {
    line-height: 1;
    position: absolute;
    left: 45%;
    top: 42%;
    transform: translate(-50%,-50%);
}

.home-title-with-arrows {
    display: flex;
    align-items: self-end;
    justify-content: space-between;
}

.slick-custom-arrows {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

    .slick-custom-arrows > div {
        background: linear-gradient(316.1deg, rgba(51, 105, 255, 0.6) -6.32%, rgba(27, 42, 84, 0.12) 92.73%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 3px 10px;
        cursor: pointer;
        border: 1.5px solid transparent;
    }

        .slick-custom-arrows > div:hover {
            background: linear-gradient(316.1deg, rgba(51, 105, 255, 0.6) -6.32%, rgba(27, 42, 84, 0.12) 92.73%);
            border: 1.5px solid #3369FF;
            box-shadow: 2px 3px 10px rgba(51, 105, 255, 0.5);
            border-radius: 8px;
        }

.promotion-slick {
    margin: 0px -10px;
}

.promotion-items {
    padding: 0px 10px;
}

.home-games-grid-container {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(17%, 1fr) );
    justify-items: center;
    grid-gap: 30px;
}

    .home-games-grid-container > div {
        width: 100%;
    }

.home-games-grid-title {
    font-size: 16px;
    text-align: center;
    padding: 0px 35px;
}

.home-games-grid-provider {
    font-size: 14px;
    color: #898F97;
    text-align: center;
}

.gameforyou-slick {
    margin: 0px -15px;
}

    .gameforyou-slick .home-games-grid-items {
        padding: 0px 15px;
        cursor: pointer;
    }

.subscribe-input-box {
    display: inline-flex;
    align-items: center;
    background: #002A61;
    border-radius: 8px;
    padding: 5px;
    column-gap: 5px;
}

    .subscribe-input-box .form-group {
        margin-bottom: 0px;
    }

    .subscribe-input-box input:focus {
        background: transparent;
        border: 0px;
        outline: unset;
        box-shadow: unset;
    }

    .subscribe-input-box input {
        background: transparent;
        border: 0px;
        min-width: 250px;
    }

    .subscribe-input-box .second button {
        background: #3369FF;
        border-radius: 8px;
        padding: 10px 20px;
        color: #fff;
    }

.home-subscribe-button {
    text-align: center;
    background: rgba(0, 0, 32, 0.5);
    border-radius: 15px;
    padding: 3em 0px 3em;
}

    .home-subscribe-button h2 {
        font-size: 30px;
        font-weight: 300;
    }

    .home-subscribe-button p {
        font-size: 17px;
        font-weight: 300;
    }

.home-left-wallet-container {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 1fr;
    justify-items: center;
    grid-gap: 20px;
}

.home-left-wallet-item {
    width: 100%;
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    padding: 40px 50px;
    display: flex;
    opacity: 1;
    /* justify-content: center; */
}

    .home-left-wallet-item .title {
        font-size: 14px;
        font-weight: 400;
    }

    .home-left-wallet-item .details {
        font-size: 20px;
        font-weight: bold;
    }

    .home-left-wallet-item:hover {
        opacity: 0.8;
        cursor: pointer;
    }

.reload-icon:hover {
    cursor: pointer;
}

.custom-icon-box {
    background: linear-gradient(316.1deg,rgba(51,105,255,.4) -6.32%,rgba(27,42,84,0) 92.73%);
    -webkit-filter: drop-shadow(0 4px 4px rgba(0,0,0,.15));
    filter: drop-shadow(0 4px 4px rgba(0,0,0,.15));
    border-radius: 8px;
    -webkit-justify-content: center;
    justify-content: center;
    box-shadow: 0 4px 7px rgba(0,0,0,.3);
}

.home-right-wallet-container {
    width: 100%;
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    /* justify-content: center; */
    padding: 15px 30px;
    height: 100%;
}

    .home-right-wallet-container .title {
        font-size: 14px;
    }

.home-wallet-box {
    display: flex;
    background: linear-gradient(273.75deg, rgba(51, 105, 255, 0.2) -4.73%, rgba(27, 42, 84, 0.5) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    padding: 7px 15px;
}

    .home-wallet-box .first {
        display: flex;
        align-items: center;
        column-gap: 15px;
    }

    .home-wallet-box .balance {
        line-height: 1;
        font-size: 16px;
    }

    .home-wallet-box .custom-icon-box {
        width: 27px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

.home-wallet-button-box {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

    .home-wallet-button-box > div {
        width: 100%;
        text-align: center;
    }

    .home-wallet-button-box button {
        background: linear-gradient(0deg, rgba(51, 105, 255, 0.2), rgba(51, 105, 255, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        padding: 12px 20px;
        font-size: 14px;
        color: #fff;
        width: 100%;
    }
/* ///end home/// */

/* ///side menu/// */
#side-menu {
    background: rgba(16, 33, 86, 1);
    border-radius: 24px;
    padding: 30px 15px;
}

.side-menu-top {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 15px 10px;
    background: rgba(0, 0, 0, 0.24);
    border-radius: 8px
}

    .side-menu-top .title {
        font-size: 16px;
    }

    .side-menu-top .wave-icon img {
        width: 25px;
    }

    .side-menu-top.login {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 30px;
    }

        .side-menu-top.login .title {
            font-size: 14px;
        }

        .side-menu-top.login .details {
            font-size: 12px;
            color: #3369FF;
        }

.side-menu-list a {
    color: #fff;
}

.side-menu-item {
    display: flex;
    align-items: center;
    column-gap: 20px;
    font-size: 14px;
    width: 100%;
    padding: 10px;
    margin: 10px 0px;
}

    .side-menu-item .side-menu-icon {
        width: 25px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-shrink: 0;
    }

        .side-menu-item .side-menu-icon img {
            width: 25px;
            height: 25px;
            filter: brightness(0) saturate(100%) invert(88%) sepia(66%) saturate(7073%) hue-rotate(175deg) brightness(137%) contrast(115%);
        }

    .side-menu-item.active {
        color: #3369FF;
        background: #0C1941;
        border-radius: 15px;
        outline: 1px solid hsla(0,0%,105.6%,.14901960784313725);
        /*        filter: brightness(0) saturate(100%) invert(30%) sepia(64%) saturate(2525%) hue-rotate(217deg) brightness(101%) contrast(101%);*/
    }

    .side-menu-item:hover {
        color: #3369FF;
        background: #0C1941;
        border-radius: 15px;
        outline: 1px solid hsla(0,0%,105.6%,.14901960784313725);
        /*        filter: brightness(0) saturate(100%) invert(30%) sepia(64%) saturate(2525%) hue-rotate(217deg) brightness(101%) contrast(101%);*/
    }

    .side-menu-item.active img {
        filter: brightness(0) saturate(100%) invert(30%) sepia(64%) saturate(2525%) hue-rotate(217deg) brightness(101%) contrast(101%);
    }

    .side-menu-item:hover img {
        filter: brightness(0) saturate(100%) invert(30%) sepia(64%) saturate(2525%) hue-rotate(217deg) brightness(101%) contrast(101%);
    }

.logout-button {
    font-size: 13px;
    background: #3369FF;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    cursor: pointer;
    padding: 10px 15px;
    position: relative;
    text-align: center;
}

    .logout-button:hover {
        background: #173586;
    }

    .logout-button .first {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translate(0, -50%);
        width: unset !important;
    }

.side-menu-login-register {
    display: flex;
    align-items: center;
    column-gap: 20px;
    justify-content: center;
    cursor: pointer;
}

    .side-menu-login-register .side-menu-login {
        color: #898F97;
    }

    .side-menu-login-register .side-menu-register {
        color: rgba(255, 217, 40, 1);
    }
/* ///end side menu/// */


/* ///footer/// */
#footer {
    /*  background: rgba(0, 0, 32, 0.5);*/
    background: #000020;
}

.footer-top-container {
    display: grid;
    grid-template-columns: 30% 25.66% 17% auto;
    grid-gap: 15px;
}

.payment-method {
    max-width: 350px;
}

.footer-payment {
    display: block;
}

    .footer-payment > li {
        margin-bottom: 20px;
    }

#footer h4 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: unset;
    color: #898F97;
}

.footer-gld {
    font-size: 14px;
    font-weight: 700;
    color: #898F97;
    /* font-family: Montserrat; */
}

footer a {
    color: #898F97;
    font-weight: 700;
    font-size: 14px;
}

    footer a:hover {
        color: #3369FF;
        /* font-family: Montserrat;  */
    }

footer .f-links li a:after {
    border-right: 1px solid #898F97;
}

.img-payment-fatty-pay {
    margin: 0px !important;
    margin-left: -10px !important;
}

.payment-method li {
    padding: 0px;
    text-align: left;
}

.footer-pagcore {
    margin-left: -13px !important;
}

.footer-hr {
    background: #d2d2d27d;
    height: 2px;
    margin: 40px 0px 20px;
}

#footer .copy-right h4 {
    font-size: 14px;
    font-weight: 700;
    font-family: "averta-thin";
    margin-bottom: 0px;
}

.copy-right {
    margin-top: 0px;
}

.copy-right-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.social-media {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

    .social-media li {
        background: #fff;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
    }

    .social-media a {
        line-height: 0;
    }

.footer-social-media-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 15px;
}

.media-icons {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.social-divider {
    height: 28px;
    width: 4px;
    border-radius: 100px;
    background-color: #fff;
}
/* ///end footer/// */

/* //register// */
.custom-input {
    /* background: linear-gradient(0deg,rgba(25,35,47,.2),rgba(25,35,47,.2)),linear-gradient(271.33deg,rgba(78,75,200,.3),rgba(254,94,171,.03)); */
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    border: 0;
    box-shadow: 0px 4px 7px 0px #0000004D;
    border-radius: 8px;
    width: 100%;
    font-size: 14px;
    color: #898F97;
    height: 50px;
    padding: 0 20px 0 75px;
}

.input-icon {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translate(0, -50%);
}

.input-right-icon {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(0, -50%);
}

.input-hidden-icon {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translate(0, -50%);
    /*bottom: 34px;*/
    /*left: 34px;*/
    /*    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;*/
}

.input-with-icon-container {
    position: relative;
}

.custom-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #898F97 !important;
    opacity: 1; /* Firefox */
}

.custom-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #898F97 !important;
}

.custom-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #898F97 !important;
}

body {
    font-family: Montserrat;
    background-color: #0e1a3c;
    color: #fff;
    position: relative;
}

#custom-modal {
    width: 90%;
    max-width: 1000px;
}

    #custom-modal .modal-content {
        background-color: #0e1a3c;
        border-radius: 8px;
        overflow: hidden;
    }

    #custom-modal .modal-body {
        /*    background: url(../img/version3/modal-bg.png);*/
        background: #0D1939;
        background-size: 100% 100% !important;
        background-repeat: no-repeat !important;
        /* height: 700px; */
        padding: 0px;
    }

.modal-body-with-image {
    display: flex;
    /* align-items: center; */
    flex-wrap: nowrap;
}

    .modal-body-with-image > div {
        width: 50%;
    }

.modal-title {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 15px;
}

    .modal-title h2 {
        font-size: 32px;
        line-height: 1;
    }

.modal-left-side {
    padding: 30px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    max-height: 700px;
    overflow-y: auto;
}

    .modal-left-side::-webkit-scrollbar-track {
        /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
        background-color: transparent;
        border-radius: 100px;
    }

    .modal-left-side::-webkit-scrollbar {
        width: 6px !important;
        background-color: transparent;
        border-radius: 100px;
    }

    .modal-left-side::-webkit-scrollbar-thumb {
        background-color: #D9D9D980;
        border-radius: 100px;
    }

    .modal-left-side > div {
        width: 100%;
        text-align: center;
    }

    .modal-left-side p {
        color: #9B9C9E;
        font-size: 14px;
    }

.custom-modal-label {
    font-size: 13px;
    color: #898F97;
    display: flex;
    align-items: center;
    column-gap: 15px;
}

    .custom-modal-label > div {
        white-space: nowrap;
    }

    .custom-modal-label:after {
        content: ' ';
        height: 1px;
        width: 100%;
        background: #898F97;
    }

    .custom-modal-label:before {
        content: ' ';
        height: 1px;
        width: 100%;
        background: #898F97;
    }

.custom-btn {
    background: #3369FF;
    box-shadow: 0px 5px 30px rgba(51, 105, 255, 0.7);
    border-radius: 15px;
    color: #fff;
    font-size: 18px;
    width: 100%;
    padding: 10px 15px;
}

    .custom-btn:disabled {
        cursor: not-allowed;
    }

.custom-btn-style-1 {
    background: #3369FF;
    /*    box-shadow: 0px 5px 30px rgba(51, 105, 255, 0.7);*/
    border-radius: 15px;
    color: #fff;
    font-size: 14px;
    width: 100%;
    padding: 0px 15px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-small-p {
    font-size: 12px;
    color: #898F97;
}

    .modal-small-p a {
        font-size: 12px;
        color: #3369FF;
        cursor: pointer;
    }

.modal-back {
    display: flex;
    column-gap: 30px;
    align-items: center;
    cursor: pointer;
}

.custom-phone-container {
    display: flex;
    align-items: center;
    column-gap: 10px;
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    border: 0;
    box-shadow: 0px 4px 7px 0px #0000004D;
    border-radius: 8px;
    width: 100%;
    font-size: 14px;
    color: #A4A4A4;
    height: 50px;
    padding: 0px 20px 0px 22px;
}

.custom-phone-input {
    font-size: 14px;
    color: #A4A4A4;
    width: 100%;
    background: transparent !important;
    border: 0px !important;
    outline: unset !important;
}

.custom-phone-container .second {
    width: 100%;
}

.custom-phone-container .third button {
    width: 130px;
    height: 25px;
    font-size: 12px;
    box-shadow: unset;
}

    .custom-phone-container .third button:disabled {
        cursor: not-allowed;
    }

.custom-select-container {
    position: relative;
    background: transparent;
    border: 0px;
    outline: unset;
    color: #A4A4A4;
    min-width: 60px;
}

.custom-phone-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #898F97 !important;
    opacity: 1; /* Firefox */
}

.custom-phone-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #898F97 !important;
}

.custom-phone-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #898F97 !important;
}

.custom-input-no-icon {
    padding-left: 15px;
}

.red-mini-text {
    color: #FF334B;
    font-size: 11px;
    text-align: left;
}

.custom-checkbox input {
    display: none;
}

.custom-checkbox span {
    border: 2px solid transparent;
    border-radius: 1px;
    background: #A4A4A499;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    display: inline-block;
    border-radius: 5px;
}

.custom-checkbox input:checked ~ span {
    background-color: #48b32dcc;
    background-image: url("../../assets/img/version3/check.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.custom-checkbox {
    font-size: 12px;
    margin-bottom: 6px;
    padding-left: 0;
    color: white;
    align-items: center;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    display: flex;
    font-weight: 400;
}

    .custom-checkbox a {
        color: #3369FFB2;
        text-decoration: underline;
    }

.custom-pin-container input:focus-visible {
    border: 2px solid #2E57C9;
}

.btn:hover {
    color: #fff;
    opacity: .8;
}
/* //end register// */

/* ///notification/// */
.right-title-box {
    margin-bottom: 2em;
}

.custom-tab-container {
    display: flex;
    align-items: center;
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    font-size: 12px;
    padding: 5px;
}

    .custom-tab-container > div {
        width: 50%;
        text-align: center;
        border-radius: 30px;
        height: 42px;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
    }

    .custom-tab-container > .active {
        background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    }

.notification-tab {
    max-width: 350px;
}

.template-box {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}

.template-box-help-centre {
    background: rgb(20, 37, 81);
    background: linear-gradient(0deg, rgba(20, 37, 81, 1) 0%, rgba(20, 38, 83, 1) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 20px 25px;
}

.template-box-style-1 {
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 10px 30px;
}

    .template-box-style-1 .title {
        font-size: 14px;
        color: #fff;
    }

    .template-box-style-1 .details {
        font-size: 11px;
        color: #898F97;
    }

.notification-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;
}

    .notification-container > .first {
        display: flex;
        align-items: center;
        column-gap: 40px;
    }

    .notification-container > .second {
        min-width: 100px;
        max-width: 100px;
    }

    .notification-container .custom-icon-box {
        padding: 10px;
    }

    .notification-container p {
        margin-bottom: 0px;
        color: #fff;
    }

    .notification-container h4 {
        font-size: 18px;
        color: #fff;
    }

    .notification-container .brief-annoucement {
        font-size: 12px;
        color: #fff;
    }

.circle-orange {
    background: #FF334B;
    position: relative;
    top: unset;
    right: unset;
    float: right;
}

.circle-green {
    background: linear-gradient(0deg, rgba(164, 164, 164, 0.2), rgba(164, 164, 164, 0.2)), linear-gradient(318.37deg, rgba(50, 249, 0, 0.8) 0%, rgba(20, 239, 199, 0.8) 94.77%);
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 50px;
    float: right;
}

.notification-date {
    color: #898F97;
    font-size: 12px;
    text-align: right;
}

/* ///end notification// */


/* ///video// */
.video-item-box {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 15px 15px;
    padding-bottom: 0px;
    height: 100%;
}

.video-tutorial-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    padding: 10px 0px 15px;
}

    .video-tutorial-content .first {
        display: flex;
        align-items: center;
        column-gap: 20px;
    }

    .video-tutorial-content .video-name {
        font-size: 14px;
        color: #fff !important;
        /* font-weight: 400; */
    }

    .video-tutorial-content .video-provider {
        font-size: 12px;
        color: #fff;
        font-style: italic;
        /* font-weight: 400; */
    }

    .video-tutorial-content .custom-icon-box {
        height: 38px;
        padding: 7px;
    }

.video {
    width: 100%;
    height: auto;
    min-height: 200px;
    border: 0;
    border-radius: 10px;
}

.search-container {
    max-width: 320px;
}
/* ///end video// */

/* /////terms//// */
.custom-description-container h3 {
    font-size: 16px;
    color: #3369FF;
}

.custom-description-container {
    font-size: 13px;
    color: #fff;
}

    .custom-description-container u {
        font-size: 16px;
    }

    .custom-description-container .table-bordered td, .table-bordered th {
        border: 1px solid #898F97;
    }

        .custom-description-container .table-bordered td:first-child {
            border-radius: 10px 10px 0px 0px;
        }

    .custom-description-container .table-bordered td {
        padding: 20px 15px;
        /* width: 100%; */
        max-width: 100px;
    }

        .custom-description-container .table-bordered td span {
            display: block;
            font-size: 12px;
        }

            .custom-description-container .table-bordered td span u {
                font-size: 12px;
                color: #3369FF;
                margin-bottom: 5px;
                display: block;
            }
/* ///end terms/// */

/* ///contact us// */
.contact-header .title {
    font-size: 24px;
}

.contact-header .details {
    font-size: 14px;
    color: #898F97;
}

.contactus-list {
    background: rgb(17, 32, 72);
    background: linear-gradient(260deg, rgba(17, 32, 72, 1) 0%, rgba(18, 31, 68, 1) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 0px;
    padding: 20px 30px;
    align-items: center;
}

    .contactus-list .channel-content {
        display: flex;
        align-items: center;
        column-gap: 30px;
    }

.channel-logo {
    max-width: 100%;
}

.contactus-list .channel-content .first {
    min-width: 30px;
    max-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 5px;
}

.custom-h4 {
    font-size: 16px;
    color: #898F97;
    font-family: Montserrat;
}

    .custom-h4:after {
        content: ' ';
        display: block;
        height: 3px;
        width: 50px;
        background: #3369FF;
        border-radius: 100px;
        margin-top: 7px;
    }

#custom-modal-small {
    width: 100%;
    max-width: 500px;
}

    #custom-modal-small .modal-content {
        background: linear-gradient(180deg, rgba(78, 75, 200, 0.2) 0%, rgba(0, 84, 182, 0) 100%), radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(242, 1, 29, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(35px);
        border-radius: 50px;
        /* overflow: hidden; */
    }

    #custom-modal-small .modal-body {
        /* background: url(../img/version3/modal-bg.png);
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important; */
        /* height: 700px; */
        padding: 30px 30px;
    }

.tip-body h6 {
    font-size: 20px;
    color: #EFEFEF;
    font-weight: unset;
}

.tip-body p {
    font-size: 14px;
    color: #A4A4A4;
    font-weight: unset;
}

.normal-select-box {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}

.normal-select {
    height: 50px;
    border: 0px;
    color: #898F97;
    width: 100%;
    padding: 0px 30px;
    -webkit-appearance: none;
    background: transparent;
    background-image: url(../img/version3/select-arrow.png);
    background-repeat: no-repeat;
    background-size: inherit;
    background-position: calc(100% - 30px) center;
    font-size: 14px;
    /* background: linear-gradient(0deg, rgba(25, 35, 47, 0.5), rgba(25, 35, 47, 0.5)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.5) 0%, rgba(254, 94, 171, 0.05) 100%); */
}

.normal-input {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    height: 50px;
    border: 0px;
    color: #898F97;
    font-size: 14px;
    width: 100%;
    padding: 0px 30px;
}

    .normal-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #898F97 !important;
        opacity: 1; /* Firefox */
    }

    .normal-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #898F97 !important;
    }

    .normal-input::-ms-input-placeholder { /* Microsoft Edge */
        color: #898F97 !important;
    }

.normal-input1 {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    height: 50px;
    border: 0px;
    color: #fff;
    font-size: 11px;
    width: 100%;
    padding: 0px 30px;
}

.form-white-input {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    height: 50px;
    border: 0px;
    color: #fff;
    font-size: 11px;
    width: 100%;
    padding: 0px 30px;
}

.tips-amount-container {
    display: flex;
    align-items: center;
    column-gap: 15px;
    justify-content: space-between;
}

    .tips-amount-container > div {
        /* width: 100px; */
        flex-grow: 1;
        text-align: center;
        border: 1.5px solid #898F97;
        border-radius: 20px;
        font-size: 12px;
        color: #898F97;
        padding: 5px 0px;
    }

        .tips-amount-container > div.active {
            border: 1.5px solid #3369FF;
            color: #3369FF;
        }

.tips-bottom-box {
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
    padding: 0px 30px;
    align-items: center;
}

    .tips-bottom-box > div:first-child {
        font-size: 14px;
    }

    .tips-bottom-box > div:last-child {
        font-size: 14px;
        color: #3369FF;
    }

.custom-button-two-col {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

    .custom-button-two-col > div {
        width: 50%;
    }

.btn-red {
    background: #FF334B;
    box-shadow: 0px 5px 30px rgba(252, 78, 78, 0.342);
}

.transac-history-search-box {
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 100%);
    background-blend-mode: overlay, normal;
    backdrop-filter: blur(6.07811px);
    border-radius: 8px;
    border: 1px solid #d0d5e330;
    padding: 0px 30px;
    height: 50px;
    position: relative;
    z-index: 1;
}

    .transac-history-search-box .th-date-search {
        font-size: 16px;
        color: #fff !important;
    }

    .transac-history-search-box .date-filter-divider {
        background: #3369FF;
        height: 11px;
        width: 2px;
        border-radius: 100px;
        margin-right: 100px;
    }

    .transac-history-search-box .th-search-btn {
        border-left: 0px;
    }

    .transac-history-search-box .first {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 600px;
    }

.filter-with-scroll-box .filter-items a {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 50px;
    border: 0px;
    padding: 6px 5px;
    color: #fff;
    width: 170px;
    font-size: 15px;
    font-family: Montserrat;
}

    .filter-with-scroll-box .filter-items a.active {
        background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        color: #3369FF;
        /* font-size: 15px; */
        font-family: Montserrat;
    }

    .filter-with-scroll-box .filter-items a:hover {
        background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        color: #3369FF;
        /* font-size: 15px; */
        font-family: Montserrat;
    }

.gray-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: transparent;
}

.gray-scroll::-webkit-scrollbar {
    height: 5px;
    background-color: transparent;
}

.gray-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #D9D9D933;
}

.custom-breadcrumb {
    list-style: none;
    padding: 0px;
    color: #3369FF;
}

    .custom-breadcrumb > li {
        display: inline;
        font-size: 14px;
    }

        .custom-breadcrumb > li:not(:last-child):after {
            content: '>';
            margin: 0px 20px;
            font-family: "averta-thin";
            font-size: 12px;
        }

        .custom-breadcrumb > li.active {
            color: #FFFFFF !important;
        }

.custom-box-list > div {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 12px 30px;
    display: flex;
}

.custom-box-list .custom-item-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    .custom-box-list .custom-item-box > .first {
        display: flex;
        align-items: center;
        column-gap: 30px;
    }

    .custom-box-list .custom-item-box .title {
        color: #898F97;
        font-size: 16px;
    }

    .custom-box-list .custom-item-box .date {
        color: #3369FF;
        font-size: 12px;
    }

#tips-report-list .custom-icon-box {
    padding: 8px;
}
/* ///end contact us//// */

/* ///wallet/// */
.wallet-list-container {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(17%, 1fr) );
    justify-items: center;
    grid-gap: 20px;
}

    .wallet-list-container > div {
        flex-grow: 1;
        display: flex;
        background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
        border-radius: 15px;
        align-items: center;
        padding: 12px 15px;
        column-gap: 20px;
        width: 100%;
    }

        .wallet-list-container > div .title {
            font-size: 12px;
        }

        .wallet-list-container > div .amount {
            font-size: 13px;
            font-weight: bold;
        }

.wallet-list-item:hover:first-child {
    cursor: default;
    opacity: unset;
}

.wallet-list-item:hover {
    cursor: pointer;
    opacity: 0.8;
}

.with-information-container {
    display: flex;
    column-gap: 30px;
}

    .with-information-container > .left {
        width: calc(100% - 330px);
    }

    .with-information-container > .right {
        width: 300px;
        /* height: 300px;
    background: #2E57C9; */
    }

.hr-left-blue, .hr-right-blue {
    background: #3369FF !important;
    width: 100%;
    max-width: 250px;
    height: 3px;
    margin: 40px 0px;
    border-radius: 100px;
    border: unset !important;
}

.hr-right-blue {
    margin-left: auto;
    margin-right: 0px;
}

.hr-left-blue {
    margin-left: 0px;
    margin-right: auto;
}

.hr-blue {
    background: #3369FF;
    width: 100%;
    height: 3px;
    border-radius: 100px
}

.wallet-button-container {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

    .wallet-button-container > div {
        display: flex;
        align-items: center;
        flex-grow: 1;
        column-gap: 20px;
        /*    background: linear-gradient(180deg,rgba(51,105,255,.24) -6.16%,rgba(27,42,84,.24) 110.96%);*/
        background: linear-gradient(260deg, rgba(19,31,66,1) 0%, rgba(21,37,84,1) 100%);
        box-shadow: 0 4px 7px rgba(0,0,0,.3), inset 0 1.5px 0.5px hsla(0,0%,100%,.1);
        border-radius: 8px;
        padding: 25px 15px;
        cursor: pointer;
    }

    .wallet-button-container .title {
        font-size: 16px;
    }

    .wallet-button-container .custom-icon-box {
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

.wallet-button-item:hover {
    cursor: pointer;
    opacity: 0.8;
}

.title-with-blue-under {
    font-size: 16px;
    font-family: Montserrat;
}

    .title-with-blue-under > div {
        font-size: 14px;
        font-family: Montserrat;
        color: #FFF;
    }

    .title-with-blue-under:after {
        content: ' ';
        background-color: #3369FF;
        display: block;
        width: 100px;
        height: 3px;
        border-radius: 100px;
        margin-top: 8px;
    }

.loyalty-content-container {
    font-size: 14px;
    text-align: justify;
    text-justify: auto;
}

.title-no-blue {
    font-size: 16px;
    font-family: Montserrat;
}

    .title-no-blue > div {
        font-size: 14px;
        font-family: Montserrat;
        color: #898F97;
    }

.transaction-history-tab-nav-container {
    display: flex;
    align-items: center;
    column-gap: 30px;
    width: 100%;
}

    .transaction-history-tab-nav-container > div {
        font-size: 14px;
        color: #898F97;
        cursor: pointer;
    }

        .transaction-history-tab-nav-container > div.active {
            font-size: 14px;
            color: #3369FF;
        }

        .transaction-history-tab-nav-container > div:after {
            content: ' ';
            display: block;
            width: 70%;
            background: transparent;
            height: 3px;
            margin: auto;
            border-radius: 100px;
            margin-top: 10px;
        }

        .transaction-history-tab-nav-container > div.active:after {
            content: ' ';
            display: block;
            width: 70%;
            background: #3369FF;
            height: 3px;
            margin: auto;
            border-radius: 100px;
            margin-top: 10px;
        }

.hr-under-tab-nav {
    background: #212C49 !important;
    width: 100%;
    height: 1px;
    margin: 0px;
    margin-top: -2px;
    border-top: unset !important;
}

.table thead tr th {
    color: #fff;
    font-size: 12px;
    border-bottom: 2px solid #212C49 !important;
}

.vip-details-container .table thead tr th {
    color: black;
}

#summary-downline-section .table thead tr th {
    color: black;
}

#loyalty-section1 .card,
.reports-page .card,
#inactive-downline .card,
.transaction-history-body .card {
    background: linear-gradient(260deg, rgba(20, 36, 77, 1) 0%, rgba(19, 33, 66, 1) 100%);
}

#loyalty-section1 .card-body,
.reports-page .card-body,
.transaction-history-body .card-body {
    padding: unset;
}

    .kpi-table .reportTable thead th,
    .reports-page .card-body .reportTable thead th,
    .transaction-history-body .card-body .reportTable thead th {
        padding: 15px 25px 10px;
    }

    #loyalty-section1 .card-body .reportTable thead th,
    #reward-history-section .reportTable thead th {
        padding: 15px 15px 10px;
    }

    .kpi-table .reportTable tbody td,
    .kpi-table .reportTable tfoot td,
    .reports-page .card-body .reportTable tbody td,
    .transaction-history-body .card-body .reportTable tbody td {
        padding: 5px 25px;
    }

    #loyalty-section1 .card-body .reportTable tbody td,
    #reward-history-section .reportTable tbody td {
        padding: 5px 15px;
    }

#loyalty-redemption-homepage .card {
    background: linear-gradient(260deg, rgba(32,40,90,1) 0%, rgba(23,29,58,1) 100%);
}

.card-body {
    padding: 10px 20px;
    overflow-x: auto;
}

    .card-body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: transparent;
    }

    .card-body::-webkit-scrollbar {
        height: 5px;
        background-color: transparent;
    }

    .card-body::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #D9D9D933;
    }

.reportTable td, .reportTable th {
    font-size: 12px;
    color: white;
    font-family: Montserrat;
}

.table td {
    border: 0px;
    font-weight: 400;
}

.right-information-box {
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    align-items: center;
    padding: 20px;
    grid-column-gap: 20px;
    column-gap: 20px;
}

.right-custom-info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;
    color: #fff;
    margin-bottom: 15px;
    font-size: 14px;
}

    .right-custom-info-item:last-child {
        margin-bottom: 0px;
    }

.right-custom-recent-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.right-custom-recent-item {
    /*border-bottom: 1px solid #D9D9D933;*/
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.right-custom-recent-box .first {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

    .right-custom-recent-box .first .title {
        font-size: 13px;
    }

    .right-custom-recent-box .first .date {
        font-size: 11px;
        color: #FFF;
    }

.right-custom-recent-box .second {
    color: #3369FF;
    font-size: 13px;
}

.right-custom-recent-view-btn {
    text-align: center;
    /*margin-top: 2.5em;*/
}

    .right-custom-recent-view-btn a {
        color: #3369FF !important;
        font-size: 10px;
        text-decoration: underline;
        cursor: pointer !important;
    }

.right-custom-game-item {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 5px 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

    .right-custom-game-item:last-child {
        margin-bottom: 0px;
    }

    .right-custom-game-item .first {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

        .right-custom-game-item .first .title {
            font-size: 12px;
        }

        .right-custom-game-item .first .provider {
            font-size: 9px;
            color: #898F97;
        }

.right-custom-downline-item {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

    .right-custom-downline-item:last-child {
        margin-bottom: 0px;
    }

    .right-custom-downline-item .first {
        display: flex;
        align-items: center;
        column-gap: 15px;
    }

        .right-custom-downline-item .first .title {
            font-size: 12px;
        }

        .right-custom-downline-item .first .id {
            font-size: 9px;
            color: #898F97;
        }

.right-downline-today {
    font-size: 12px;
    display: flex;
    align-items: center;
    column-gap: 15px;
}

    .right-downline-today:before {
        content: ' ';
        width: 4px;
        height: 4px;
        background: #3369FF;
        display: block;
    }

#deposit-section .right-title-box {
    margin-bottom: 0px;
}

.color-a4 {
    color: #A4A4A4;
}

.text-gold {
    /*  color: #3369FF !important;*/
}

.color-main {
    color: #3369FF;
}

.color-gray {
    color: #898F97 !important;
}

.color-red {
    color: #FF334B !important;
}

.color-green {
    background: linear-gradient(318.37deg, rgba(50, 249, 0, 0.8) 0%, rgba(20, 239, 199, 0.8) 94.77%), linear-gradient(0deg, rgba(164, 164, 164, 0.2), rgba(164, 164, 164, 0.2));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#deposit-section .deposit-container-box {
    /*  background: rgba(16, 33, 86, 1)*/
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px 0px #0000004D;
}

    #deposit-section .deposit-container-box:first-child {
        min-height: 540px;
    }

.deposit-section-box {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 20px 20px 0px;
}

.deposit-slick-item {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 1px solid #202452;
    font-family: Montserrat;
    height: 100%;
    margin: 0px 7px;
    min-height: 370px;
}

    .deposit-slick-item.active {
        outline: 2px solid #3369FF;
    }

#deposit-section .slick-list {
    padding: 5px 0;
}

.deposit-bonus-image {
    border-radius: 8px;
    overflow: hidden;
    /*  max-width: 250px;*/
    max-height: 150px;
}

.deposit-slick-item.active .deposit-bonus-image:after {
    background: #3369FF;
}

.deposit-bonus-details {
    font-family: Montserrat;
    min-height: 145px;
}

.deposit-slick .slick-slide > div {
    padding-bottom: 20px;
}

#deposit-tabs {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

    #deposit-tabs a {
        font-family: "averta-semibold";
        color: #898F97;
        display: block;
        padding-bottom: 10px;
        cursor: pointer;
        /* margin-bottom: 0px; */
    }

    #deposit-tabs .active {
        color: #3369FF;
        position: relative;
        background-color: transparent;
    }

    #deposit-tabs li {
        margin-right: 40px;
    }

    #deposit-tabs .active:after {
        content: " ";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #3369FF;
        bottom: -2px;
        z-index: 1;
        left: 0px;
    }

.deposit-alert-warning {
    color: white;
    outline: #3369FF 2px solid;
    border-radius: 10px;
    background: rgba(16, 33, 86, 1);
}

/*.css-2b097c-container {
    background: transparent url(../img/assets/drop.svg) no-repeat 95% center !important;
}
*/
.css-2ocv9q-control {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 0px;
}

.reminder {
    color: #FF334B;
    font-size: 12px;
    font-style: italic;
}

.p-note {
    color: #898F97;
    font-size: 12px;
    font-style: italic;
}

#deposit-section .accordion-bonusdeposit {
    padding: 0px;
    background: transparent;
    margin: 0px;
    border-radius: 0px;
}

    #deposit-section .accordion-bonusdeposit .accordion-header {
        border-bottom: 1px solid #D9D9D91A;
        /* margin-bottom: 15px; */
        padding: 15px;
    }

#deposit-section .accordion-button .accordion-circle-icon {
    background: transparent;
    position: relative;
    border: 2px solid #898F97;
    width: 18px;
    height: 18px;
    border-radius: 100px;
}

    #deposit-section .accordion-button .accordion-circle-icon:after {
        content: ' ';
        background: #898F97;
        position: absolute;
        width: 9px;
        height: 9px;
        left: 51%;
        top: 50%;
        transform: translate(-50%,-50%);
        /* z-index: -1; */
        border-radius: 100px;
    }

#deposit-section .accordion-container .accordion-header .accordion-button {
    color: #898F97;
    font-family: Montserrat;
}

#deposit-section .accordion-container .accordion-button:after {
    color: #898F97;
}

#deposit-section .accordion-container .accordion-header .accordion-button[aria-expanded="true"] {
    color: #3369FF;
}

#deposit-section .accordion-container .accordion-button[aria-expanded="true"]:after {
    color: #3369FF;
}

#deposit-section .accordion-button[aria-expanded="true"] .accordion-circle-icon {
    background: transparent;
    position: relative;
    border: 2px solid #3369FF;
    width: 18px;
    height: 18px;
    border-radius: 100px;
}

    #deposit-section .accordion-button[aria-expanded="true"] .accordion-circle-icon:after {
        content: ' ';
        background: #3369FF;
        position: absolute;
        width: 9px;
        height: 9px;
        left: 51%;
        top: 50%;
        transform: translate(-50%,-50%);
        /* z-index: -1; */
        border-radius: 100px;
    }

#deposit-section .accordion-button > div {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.payment-methods-radio .form-checkbox .option.active {
    border-color: #3369FF;
    background: transparent 0% 0% no-repeat padding-box;
}

.provider-wrapper .form-checkbox .option.active {
    border-color: #3369FF;
    background: transparent 0% 0% no-repeat padding-box;
}

#deposit-section .accordion-body {
    border-bottom: 1px solid #D9D9D91A;
    padding-bottom: 20px;
}

.deposit-table tr:nth-child(n+1) td:first-child {
    background: #1c2662;
}

.deposit-table td:nth-child(n+1) {
    background: #1c2662;
}

.deposit-table .third-part {
    border-bottom: 1px solid #D9D9D91A !important;
}

.deposit-table .custom-checkbox input[type="radio"] {
    border-color: #898F97;
    margin-left: 40%;
}

    .deposit-table .custom-checkbox input[type="radio"]:checked {
        background-color: #3369FF;
        border-color: #898F97;
    }

.deposit-table tr:nth-child(n+1) td:first-child {
    vertical-align: middle;
}

.deposit-table tr:first-child td:last-child {
    border-top-right-radius: 10px;
}

.deposit-table tr:last-child td:first-child {
    border-bottom-left-radius: 0px;
    border-bottom: 1px solid #D9D9D91A !important;
}

.deposit-table .border-top.border-bottom {
    max-width: 30px;
    word-wrap: break-word;
    border-bottom: 1px solid #D9D9D91A !important;
    vertical-align: middle;
}

.bank-text.img-col#img-col-id {
    max-width: 50px;
    word-wrap: break-word;
    border-bottom: 1px solid #D9D9D91A;
    vertical-align: middle;
}

.bank-img {
    width: 70px;
    height: auto;
}

.deposit-table tr:nth-last-child(2) td:first-child {
    border-bottom-left-radius: 10px;
    border-bottom: 1px solid #D9D9D91A;
}

.normal-deposit-input-div {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.deposit.input-group {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    border-radius: 8px;
}

    .deposit.input-group .form-control {
        flex-wrap: unset !important;
        background: transparent;
        height: 50px;
        border: 0px;
        color: #fff;
        font-size: 11px;
        width: 100%;
        padding: 0px 30px;
    }

.deposit .close-button {
    display: flex;
    align-items: center;
    padding-right: 5px;
    height: 50px;
}

.dropzone {
    background: transparent !important;
    border: 1px dashed #3369ff !important;
    -webkit-filter: drop-shadow(0 4px 7px rgba(0,0,0,.3));
    filter: drop-shadow(0 4px 7px rgba(0,0,0,.3));
    border-radius: 8px;
}

    .dropzone h4 {
        color: #fff;
    }

.bank-text {
    color: #fff;
    font-weight: 400;
}

    .bank-text .float-right-icon svg {
        color: #3369FF;
        width: 17px !important;
    }

    .bank-text .float-right-icon {
        background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
        border-radius: 100px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

.add-amount-container {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

    .add-amount-container > div {
        background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        padding: 0px 7px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        cursor: pointer;
        border: 1.5px solid transparent;
        font-size: 12px;
    }

        .add-amount-container > div:hover {
            border: 1.5px solid #3369FF;
            color: #3369FF;
        }

.custom-box-with-icon-container {
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #898F97;
    font-size: 14px;
}

    .custom-box-with-icon-container .first {
        display: flex;
        align-items: center;
        column-gap: 30px;
        color: #fff;
    }

    .custom-box-with-icon-container .second {
        color: #fff;
    }

    .custom-box-with-icon-container .custom-icon-box {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

.custom-box-with-icon-side {
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #898F97;
    font-size: 14px;
    cursor: pointer;
}

    .custom-box-with-icon-side .first {
        display: flex;
        align-items: center;
        column-gap: 30px;
        color: #fff;
    }

    .custom-box-with-icon-side .custom-icon-box {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .custom-box-with-icon-side a {
        color: #fff;
    }

.payment-custom-radio {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
}

    .payment-custom-radio input {
        display: none;
    }

.payment-custom-radio-label {
    line-height: 1;
    color: #898F97;
    font-size: 14px;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

    .payment-custom-radio-label > div {
        display: flex;
        align-items: center;
        column-gap: 30px;
    }

.payment-custom-radio .checkmark-border {
    border: 2px solid #898F97;
    border-radius: 100px;
    display: inline-block;
    line-height: 0;
}

.payment-custom-radio .checkmark-dot {
    border-radius: 100px;
    background: #898F97;
    width: 7px;
    height: 7px;
    display: inline-block;
    margin: 2px;
}

.payment-custom-radio input:checked ~ .checkmark-border {
    border: 2px solid #3369FF;
}

    .payment-custom-radio input:checked ~ .checkmark-border .checkmark-dot {
        background: #3369FF;
    }

.payment-custom-radio input:checked ~ .checkmark-title {
    color: #3369FF;
}

.payment-custom-radio input:checked ~ div .checkmark-title {
    color: #3369FF;
}

.payment-custom-radio input:checked ~ div .checkmark-details {
    color: #3369FF;
}

.payment-list-item {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 15px 30px;
    margin-bottom: 15px;
}

.withdraw-edit-button-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
}

    .withdraw-edit-button-box a {
        color: #3369FF;
        text-decoration: underline;
    }

.payment-dropdown-item-box {
    border-top: 1px solid #D9D9D91A;
    padding: 20px 30px 0px 50px;
    margin-top: 20px;
}

    .payment-dropdown-item-box .title {
        font-size: 14px;
    }

    .payment-dropdown-item-box .details {
        font-size: 12px;
        color: #A4A4A4;
    }

.custom-check-checkbox span {
    border: 2px solid transparent;
    border-radius: 1px;
    background: #A4A4A499;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    display: inline-block;
    border-radius: 5px;
}

.custom-check-checkbox input:checked ~ span {
    background-color: rgba(72,179,45,.8);
    background-image: url(../img/version3/checkbox-check.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
}

.custom-check-checkbox input {
    display: none;
}

.custom-check-checkbox {
    font-size: 12px;
    margin-bottom: 6px;
    padding-left: 0;
    color: #A4A4A4;
    align-items: center;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    display: flex;
    font-weight: 400;
}

.custom-two-header-tab-container {
    display: flex;
    align-items: center;
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    font-size: 12px;
    padding: 5px;
    max-width: 350px;
}

    .custom-two-header-tab-container > div {
        width: 50%;
        text-align: center;
        border-radius: 30px;
        height: 42px;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
    }

    .custom-two-header-tab-container > .active {
        background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    }

.form-divider .transfer-icon {
    width: auto;
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 50px;
    min-width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.form-divider .css-1f71ezs-control {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%) !important;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    height: 50px;
    border: 0px;
    color: #fff;
    font-size: 12px;
}

.form-divider .css-1qkv4rq-placeholder {
    font-size: 11px;
}

.form-divider .css-18t12i7-control {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%) !important;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    height: 50px;
    border: 0px;
    color: #fff;
    font-size: 11px;
}

.form-divider .css-o01cwa-singleValue {
    font-size: 11px;
}

.claim-btn {
    background: #3369FF;
    color: #fff;
    border-radius: 20px;
    padding: 5px 30px;
    border: 0px;
    font-size: 12px;
    cursor: pointer;
}

.commission-item-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    .commission-item-box .title {
        font-size: 14px;
        color: #fff;
        font-weight: bold;
    }

    .commission-item-box .date {
        font-size: 12px;
        color: #fff;
        font-weight: 300;
    }

.unclaimed-tag {
    font-size: 10px;
    background: #FF334B;
    border-radius: 20px;
    padding: 8px 30px;
}

.unclaimed-mini-tag {
    font-size: 10px;
    background: #FF334B;
    border-radius: 20px;
    padding: 3px 7px;
}

.claimed-tag {
    font-size: 10px;
    background: linear-gradient(0deg, rgba(164, 164, 164, 0.2), rgba(164, 164, 164, 0.2)), linear-gradient(318.37deg, rgba(50, 249, 0, 0.8) 0%, rgba(20, 239, 199, 0.8) 94.77%);
    border-radius: 20px;
    padding: 8px 30px;
}

.custom-two-column-divider {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

    .custom-two-column-divider > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 15px;
        background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 15px 30px;
        width: 50%;
    }

    .custom-two-column-divider .title {
        font-size: 14px;
    }

    .custom-two-column-divider .details {
        font-size: 15px;
    }

.card {
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 0px;
}

.vip-details-container .card {
    background: white;
}

#summary-downline-section .card {
    background: white;
}

.rewards-calendar-month {
    font-size: 16px;
    color: #fff;
}

.rewards-calendar-day {
    text-align: center;
    font-size: 14px;
    /*  color: #898F97;
  padding: 8px 0px;*/
}

    .rewards-calendar-day.selected {
        background: #3369FF;
        color: #fff;
        border-radius: 10px;
        padding: 6px 18px;
    }

/*.custom-select-white-blend {
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%,rgba(51,105,255,.2) 0,hsla(0,0%,100%,.0447917) 77.08%,hsla(0,0%,100%,0) 100%);
    background-blend-mode: overlay,normal;
    -webkit-backdrop-filter: blur(6.07811px);
    backdrop-filter: blur(6.07811px);
    border: 1px solid hsla(0,0%,79.6%,.14901960784313725);
    border-radius: 8px;
}

    .custom-select-white-blend > select {
        height: 40px;
        width: 100%;
        border: 0px;
        color: #3369ff;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-size: 14px;
        padding: 0px 20px;
        background: radial-gradient(90.16% 143.01% at 15.32% 21.04%,rgba(51,105,255,.2) 0,hsla(0,0%,100%,.0447917) 77.08%,hsla(0,0%,100%,0) 100%);
        background-image: url(../img/version3/select-arrow-blue-down.png);
        background-repeat: no-repeat;
        background-position: calc(100% - 20px) center;
    }

        .custom-select-white-blend > select > option {
            color: white;
            font-size: 14px;
            background: rgba(0, 0, 32);
        }*/

.rewards-game-item {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    margin: 0px 5px;
    padding: 8px 8px;
    margin-bottom: 20px;
}

.rewards-game-list-container {
    display: grid;
    /* grid-template-columns: repeat(5,1fr); */
    grid-template-columns: repeat( auto-fit, minmax(22%, 1fr) );
    /* grid-template-rows: 2fr; */
    justify-items: center;
    grid-gap: 10px;
    text-align: center;
}

.rewards-game-item .title {
    color: #fff;
    font-size: 14px;
}

.rewards-game-item .details {
    color: #3369FF;
    font-size: 12px;
    font-weight: bold;
}

.thc-result-mainbox {
    /*  background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);*/
    background: linear-gradient(260deg, rgba(20, 36, 77, 1) 0%, rgba(19, 33, 66, 1) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: unset;
}

.kpi-grid {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(22%, 1fr) );
    /* justify-items: center; */
    grid-gap: 20px;
}

    .kpi-grid > div {
        background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 15px 20px 7px;
        font-size: 12px;
    }

    .kpi-grid .kpi-title {
        font-size: 16px;
        margin-bottom: 10px;
    }

.kpi-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.kpi-select-search {
    height: 40px;
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%,rgba(51,105,255,.2) 0,hsla(0,0%,100%,.0447917) 77.08%,hsla(0,0%,100%,0) 200%);
    border-radius: 8px;
    border: 0.6px solid #5B70AA;
    box-shadow: 0 4px 7px rgba(0,0,0,.3);
}

.kip-select-backgroud .custom-select-kpi-container {
    position: inherit;
    background: transparent;
    border: 1px;
    outline: unset;
    color: #3369ff;
    opacity: .9;
    padding-left: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    box-sizing: border-box;
    width: 100%;
}

.kpi-table {
    background: white;/*linear-gradient(260deg, rgba(20, 36, 77, 1) 0%, rgba(19, 33, 66, 1) 100%);*/
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    /*  padding: 15px 20px 0px;*/
    overflow: hidden;
}

table tfoot {
    position: relative;
    z-index: 1;
}

    table tfoot:after {
        content: ' ';
        width: calc(100% + 40px);
        height: 100%;
        position: absolute;
        left: -20px;
        top: 0px;
        background: linear-gradient(260deg, rgba(20, 36, 77, 1) 0%, rgba(19, 33, 66, 1) 100%);
        z-index: -1;
        border-radius: 0px 0px 7px 7px;
        border-top: 2px solid #212C49 !important;
    }

.table {
    margin-bottom: 0px;
}
/* ///end wallet/// */

/* ////reports//////// */
.reports-top-menu-container {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(45%, 1fr) );
    grid-gap: 20px;
}

    .reports-top-menu-container > div {
        color: #fff;
    }

        .reports-top-menu-container > div.active {
            background: #314d999d;
            color: #3369FF;
        }

.reportTable .hoverable {
    color: #3369ff;
    text-decoration: underline;
}

/*.bonus-report-select {
    height: 40px;
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%,rgba(51,105,255,.2) 0,hsla(0,0%,100%,.0447917) 77.08%,hsla(0,0%,100%,0) 200%);
    border-radius: 8px;
    border: 0.6px solid #898f97;
    box-shadow: 0 4px 7px rgba(0,0,0,.3);
}

    .bonus-report-select .custom-select-bonus-container {*/
/* position: inherit; */
/*background: transparent;
        border: 1px;
        outline: unset;
        color: #3369ff;*/
/* padding-left: 30px; */
/*box-sizing: border-box;
        width: 100%;
        height: 40px;
        font-size: 14px;
    }

    .bonus-report-select .css-16hr6bx-control {
        background: transparent;
        border: 0px;
    }*/

/*.bonus-report-select .custom-select-bonus-container {
        background: transparent url(../img/version3/select-arrow-blue-down.png) no-repeat calc(100% - 20px) center !important;
        min-height: 40px !important;
    }*/

/*.bonus-report-select .css-1bxi0x5-singleValue {
        color: #3369ff;
        font-size: 12px;
    }*/

#rollover-statement-box .date {
    font-size: 10px;
    color: #898F97;
}

#rollover-statement-box .title {
    font-size: 12px;
    color: #fff;
}

#rollover-statement-box .details {
    font-size: 12px;
    color: #A4A4A4;
}

.badge.badge-green {
    background: linear-gradient(0deg, rgba(164, 164, 164, 0.2), rgba(164, 164, 164, 0.2)), linear-gradient(318.37deg, rgba(50, 249, 0, 0.8) 0%, rgba(20, 239, 199, 0.8) 94.77%);
    border-radius: 20px;
    font-size: 12px;
    color: #fff;
    font-weight: unset;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
}

.badge.badge-danger {
    background: #FF334B;
    border-radius: 20px;
    font-size: 12px;
    color: #fff;
    font-weight: unset;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
}

/*#game-record-section .css-2b097c-container {
    background: transparent url(../img/assets/drop.svg) no-repeat 95% center !important;
    min-height: 40px !important;
}*/

#game-record-section .css-msgrqo-control {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 0px;
    height: 40px;
}

#game-record-section .css-1ccq0ql-control {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 0px;
    height: 40px;
}

#game-record .custom-select-game-record-container {
    /*     position: inherit; */
    background: unset !important;
    border: 1px;
    outline: unset;
    color: #3369ff;
    /*     padding-left: 30px; */
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    font-size: 14px;
}

.rdt .rdtPicker {
    background: #fff;
    color: #000;
    font-weight: 300;
}

.downline-top-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-bottom: 0;
}

    .downline-top-container > .first {
        background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 0px 20px;
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

        .downline-top-container > .first .details {
            color: #FFD928;
            font-size: 30px;
        }

        .downline-top-container > .first .title {
            font-size: 16px;
        }

    .downline-top-container > .second {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

        .downline-top-container > .second > div {
            background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
            box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
            border-radius: 8px;
            padding: 14px 20px;
            text-align: center;
            width: 100%;
        }

        .downline-top-container > .second .new {
            color: #FFFFFF;
            font-size: 14px;
        }

            .downline-top-container > .second .new span {
                color: #3369FF;
                margin-right: 5px;
            }

            .downline-top-container > .second .new span {
                color: #FFD928;
                margin-right: 5px;
            }

        .downline-top-container > .second .total {
            display: flex;
            column-gap: 10px;
            align-items: center;
            justify-content: center;
        }

#downline-search-box-main {
    /* height: 40px; */
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%,rgba(51,105,255,.2) 0,hsla(0,0%,100%,.0447917) 77.08%,hsla(0,0%,100%,0) 200%);
    border-radius: 8px;
    border: 1.5px solid #5B70AA !important;
    box-shadow: 0 4px 7px rgba(0,0,0,.3);
}

    #downline-search-box-main input {
        height: 40px;
        background: transparent;
        font-size: 12px;
        padding: 0px 20px;
        border: 0px;
        color: white;
    }

    #downline-search-box-main .input-group {
        margin-bottom: 0px !important;
    }

    #downline-search-box-main .form-group {
        margin-bottom: 0px !important;
    }

    #downline-search-box-main input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #fff !important;
        opacity: 1; /* Firefox */
    }

    #downline-search-box-main input:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #898F97 !important;
    }

    #downline-search-box-main input::-ms-input-placeholder { /* Microsoft Edge */
        color: #898F97 !important;
    }

    #downline-search-box-main .input-group-text {
        background: transparent;
        padding-right: 20px;
    }

    #downline-search-box-main > div > div:nth-child(2) {
        height: unset;
    }

        #downline-search-box-main > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) {
            top: 50% !important;
        }

.downline-main-boxes {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}

    .downline-main-boxes .title {
        font-size: 14px;
        font-family: Montserrat;
    }

    .downline-main-boxes .id {
        font-size: 11px;
        font-family: Montserrat;
        margin-top: 5px;
    }

.downline-group-main-boxes {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    display: flex;
    padding: 0px 25px;
    border-radius: 8px;
    margin-top: 20px;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    padding: 15px 30px;
    font-size: 12px;
}

.group-row-right {
    column-gap: 15px;
}

.create-downline-btn {
    font-size: 14px;
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 0px 20px;
    width: 100%;
    color: #fff;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    font-weight: 400;
}

.no-group-name {
    font-size: 12px;
}

.group-name-label {
    font-size: 12px;
    color: #fff;
}

#downline-group-section .template-box {
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#downline-group-section .custom-input-style-1 {
    border: 0px;
    padding: 0px;
    background: transparent !important;
    border-radius: 0px !important;
}

#downline-group-section .template-box .first {
    width: 70%;
    max-width: 250px;
}

.input-checkbox-custom {
    display: none;
}

.checked-icon {
    display: none;
}

.input-checkbox-custom:checked + .downline-group-checkbox .checked-icon {
    display: block;
}

.input-checkbox-custom:checked + .downline-group-checkbox .unchecked-icon {
    display: none;
}

#downline-group-section #downline-group-box .title {
    font-size: 14px;
    color: #fff;
}

#downline-group-section #downline-group-box .title-small {
    font-size: 11px;
    color: #fff;
}

#downline-group-section #downline-group-box .id {
    font-size: 10px;
    color: #898F97;
}

#downline-group-section #downline-group-box {
    justify-content: unset;
    column-gap: 30px;
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 15px 30px;
}

.downline-edit-container > div {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 10px 30px;
    margin-bottom: 20px;
}

#commrate th, #commrate td {
    color: #A4A4A4;
    font-size: 12px;
    padding: 5px 0px;
}

#commrate .downline-prod-name {
    font-size: 14px;
    color: #fff;
}

.edit-downline-table {
    width: 100%;
}

#downlinetableform .edit-downline-table .data-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    #downlinetableform .edit-downline-table .data-row .row-data-1 {
        width: 70%;
    }

    #downlinetableform .edit-downline-table .data-row.category {
        /* background: linear-gradient(0deg,rgba(25,35,47,.2),rgba(25,35,47,.2)),linear-gradient(271.33deg,rgba(78,75,200,.3),rgba(254,94,171,.03));
    box-shadow: 0 4px 7px rgba(0,0,0,.3);
    border-radius: 8px; */
        padding: 10px 20px;
        column-gap: 40px;
    }

    #downlinetableform .edit-downline-table .data-row.head-category {
        background: linear-gradient(0deg,rgba(25,35,47,.2),rgba(25,35,47,.2)),linear-gradient(271.33deg,rgba(78,75,200,.3),rgba(254,94,171,.03));
        box-shadow: 0 4px 7px rgba(0,0,0,.3);
        border-radius: 8px;
        padding: 10px 20px;
    }

        #downlinetableform .edit-downline-table .data-row.head-category.category-lottery-first {
            background: linear-gradient(0deg,rgba(25,35,47,.2),rgba(25,35,47,.2)),linear-gradient(271.33deg,rgba(78,75,200,.3),rgba(254,94,171,.03));
            box-shadow: none !important;
            border-radius: 8px 8px 0px 0px;
            padding: 10px 20px;
        }

        #downlinetableform .edit-downline-table .data-row.head-category.category-lottery-second {
            background: linear-gradient(0deg,rgba(25,35,47,.2),rgba(25,35,47,.2)),linear-gradient(271.33deg,rgba(78,75,200,.3),rgba(254,94,171,.03));
            box-shadow: 0 4px 7px rgba(0,0,0,.3);
            border-radius: 0px 0px 8px 8px;
            padding: 10px 20px;
        }

#downlinetableform .row-data-max {
    background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    font-size: 10px;
    color: #fff;
    width: 45px;
    height: 45px;
    border-radius: 100px !important;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.3);
}

#downlinetableform .row-data-3 .plus-minus-wrapper {
    display: flex;
    column-gap: 20px;
    align-items: center;
    background: linear-gradient(0deg,rgba(51,105,255,.1),rgba(51,105,255,.1)),linear-gradient(271.54deg,rgba(51,105,255,.12) -18.33%,rgba(27,42,84,.3));
    box-shadow: 0 4px 7px rgba(0,0,0,.3);
    border-radius: 30px;
    padding: 0 20px;
    height: 45px;
    border: 0px
}

#downlinetableform .row-data-3 .bonusComm-option-select {
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    width: 80px;
}

#downlinetableform .row-data-3 .edit-plus-minus-icon {
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.row-data-title {
    font-weight: 400;
    font-family: "averta-thin";
}

#edit-downline .border-bot-black {
    border-color: #FFFFFF1A;
}

#edit-downline-group .border-bot-black {
    border-color: #FFFFFF1A;
}

.custom-main-boxes {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 20px 30px;
    display: flex;
    align-items: center;
}

.referral-link .title {
    font-size: 14px;
    color: #fff;
}

#referral-form .referral-edit-box {
    padding: 10px 20px;
    border: 0px;
    align-items: center;
}

    #referral-form .referral-edit-box.head-category {
        background: linear-gradient(0deg,rgba(25,35,47,.2),rgba(25,35,47,.2)),linear-gradient(271.33deg,rgba(78,75,200,.3),rgba(254,94,171,.03));
        box-shadow: 0 4px 7px rgba(0,0,0,.3);
        border-radius: 8px;
        padding: 10px 20px;
        border: 0px;
        align-items: center;
    }

#referral-form .hr-referral-category {
    border-bottom: 1px solid #FFFFFF1A;
    margin-bottom: 25px;
    padding-bottom: 25px;
}

.referral-edit-box h6 {
    font-weight: 400;
    font-family: "averta-thin";
}

.referral-edit-box.head-category h6 {
    font-family: "averta-semibold";
    width: 30%;
}

.referral-add-minus {
    margin-left: 0px;
    display: flex;
    grid-column-gap: 20px;
    column-gap: 20px;
    align-items: center;
    background: linear-gradient(0deg,rgba(51,105,255,.1),rgba(51,105,255,.1)),linear-gradient(271.54deg,rgba(51,105,255,.12) -18.33%,rgba(27,42,84,.3));
    box-shadow: 0 4px 7px rgba(0,0,0,.3);
    border-radius: 30px;
    padding: 0 20px;
    height: 45px;
    border: 0px;
}

.referral-max-box {
    background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    font-size: 10px;
    color: #fff;
    width: 45px;
    height: 45px;
    border-radius: 100px !important;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.3);
}

.referral-edit-details {
    column-gap: 40px;
}

.referral-add-minus select {
    width: 65px;
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
}

.referral-qr-container {
    display: flex;
    column-gap: 40px;
}

    .referral-qr-container .first {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

        .referral-qr-container .first .title {
            font-size: 12px;
            margin-bottom: 8px;
        }

        .referral-qr-container .first .referral-code-box {
            background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
            box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
            border-radius: 8px;
            padding: 15px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
        }

    .referral-qr-container .second {
        background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 10px 15px;
    }

.referral-qr-container-div {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

    .referral-qr-container-div .title {
        font-size: 12px;
        margin-bottom: 8px;
    }

    .referral-qr-container-div .referral-code-box {
        background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 15px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
    }

.rate-downline {
    color: #3369FF !important;
    font-size: 14px;
    text-decoration: underline !important;
    cursor: pointer;
}

#my-tabs a {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%);
    min-width: 100px;
    text-align: center;
    font-size: 11px;
    color: #fff;
    border-radius: 100px;
    padding: 7px 5px;
}

#my-tabs li {
    margin: 0px;
}

#my-tabs {
    align-items: center;
    column-gap: 10px;
    border-bottom: 0px;
    grid-row-gap: 10px;
}

    #my-tabs .active:after {
        display: none;
    }

    #my-tabs .active a {
        background: linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%), linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1));
        color: #3369FF;
    }

.referral-game-details {
    padding: 0px;
}

.referral-list-box {
    padding: 0px 30px;
}

.tab-pane .referral-list-box:not(:last-child) {
    border-bottom: 1px solid #FFFFFF1A;
    padding-bottom: 15px
}

.referral-list-box h6 {
    font-size: 14px;
}

    .referral-list-box h6 span {
        color: #3369FF;
    }

.product-rate-row > div:first-child {
    font-size: 12px;
}

.product-rate-row > div:last-child {
    font-size: 14px;
}

.referral-all-member-container {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(45%, 1fr) );
    justify-items: center;
    grid-gap: 20px;
}

    .referral-all-member-container > div {
        width: 100%;
    }

#modal-small-custom .modal-content {
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(242, 1, 29, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%), linear-gradient(180deg, rgba(78, 75, 200, 0.2) 0%, rgba(0, 84, 182, 0) 100%);
    /* background: rgba(37, 42, 82, 0.16); */
    backdrop-filter: blur(35px);
    border-radius: 50px;
    box-shadow: 0px 4px 20px 0px #00000066;
    padding: 20px;
}

    #modal-small-custom .modal-content button {
        box-shadow: unset;
    }

#modal-small-custom h3 {
    font-size: 24px;
    font-weight: 400;
}

#modal-small-custom .ref-share {
    /*    margin: auto;
    display: inline-block;*/
}

.settings-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

    .settings-user .first {
        display: flex;
        column-gap: 30px;
        align-items: center;
    }

.reminder-box {
    display: flex;
    column-gap: 30px;
    align-items: center;
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px 0px #0000004D;
    border-radius: 8px;
    padding: 10px 30px;
    min-height: 60px;
}

    .reminder-box .title {
        font-size: 11px;
        color: #898F97;
    }

    .reminder-box .link {
        font-size: 11px;
        color: #3369FF;
    }
/* ///end reports/////// */

/* ///setttings/ */
.settings-accordion-item > .settings-acc-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #FFF;
}

.settings-accordion-item .settings-acc-title .img2 {
    display: none;
}

.settings-accordion-item .settings-acc-title[aria-expanded="true"] .img1 {
    display: none;
}

.settings-accordion-item .settings-acc-title[aria-expanded="true"] .img2 {
    display: block;
}

.settings-acc-details label {
    font-size: 12px;
    color: #FFF;
    font-weight: 400;
}

.settings-acc-details {
    border-top: 1px solid #FFFFFF1A;
    margin-top: 20px;
}

#settings-section .settings-item-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #FFFFFF;
}

.setting-points-container {
    display: flex;
    align-items: center;
    column-gap: 30px;
    border-bottom: 1px solid #898F97;
    padding-bottom: 20px;
}

    .setting-points-container > .first,
    .setting-points-container > .third {
        width: 100%;
        text-align: center;
    }

    .setting-points-container > .second {
        width: 1px;
        height: 50px;
        background: #898F97;
    }

    .setting-points-container .title {
        color: #898F97;
        font-size: 12px;
    }

    .setting-points-container .details {
        color: #3369FF;
        font-size: 16px;
    }

#result4d .title-4dresult {
    font-size: 14px;
    font-weight: 400;
    font-family: Montserrat;
}

#result4d .result4d-search-icon {
    position: absolute;
    right: 15px;
    bottom: 8px;
    cursor: pointer;
}

#result4d .form-white-search-input {
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%,rgba(51,105,255,.2) 0,hsla(0,0%,100%,.0447917) 77.08%,hsla(0,0%,100%,0) 100%);
    background-blend-mode: overlay,normal;
    -webkit-backdrop-filter: blur(6.07811px);
    backdrop-filter: blur(6.07811px);
    border: 1px solid hsla(0,0%,79.6%,.14901960784313725);
    color: #fff;
    font-size: 12px;
    border-radius: 8px;
    padding: 0 20px;
    height: 40px;
    font-family: Montserrat;
    z-index: 1;
}

#result4d .react-datepicker__input-container {
    width: 230px;
}

#result4d .form-white-search-input .react-datepicker__input-container input {
    line-height: 1;
    color: #FFFF;
    margin-top: 2px;
    cursor: pointer;
}

#result4d .form-white-search-input .btn-icon i {
    color: #FFFF;
}

#result4d .prize-type.font-12 {
    color: black;
}

#result4d .white-background {
    background: #fff;
    border: 0px !important;
}

#result4d .table thead tr th {
    border: none;
    border-top: 1px solid black !important;
    border-bottom: 1px solid black !important;
    border-left: 1px solid white !important;
    border-right: 1px solid white !important;
    color: black !important;
    font-size: 14px;
}

#result4d .table.section-2 thead tr th {
  border-left: unset !important;
  border-right: unset !important;
}

#result4d .prize-type {
  color: black;
}

#result4d .table {
    color: black !important;
}

    #result4d .table tbody td {
        font-size: 14px;
    }
/* ///end settings/// */


/* ///vip events// */
.vip-main-nav > div {
    font-size: 14px;
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 0px 20px;
    width: 100%;
    color: #fff;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    font-weight: 400;
    cursor: pointer;
}

.vip-main-nav {
    display: flex;
    column-gap: 20px;
    align-items: center;
}

.shareholder-my-income {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    column-gap: 20px;
}

.my-income-card {
    width: 100%;
    text-align: center;
    padding: 8px;
}

.my-income-card-content .content-value {
    font-size: 14px;
    color: #3369FF;
    margin-top: 10px;
}

.vip-event-company-summary-title {
    margin: 0px;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 600;
    color: #fff;
    margin-bottom: 20px;
}

.vip-details-container .table td {
    font-size: 12px;
    color: black;
    font-family: Montserrat;
}

.vip-details-container .table .grandtotal {
    position: relative;
}

.shareholder-dropdown {
    margin-left: 15px;
}

.text-red {
    color: #FF334B !important;
}

.card-package .table .grandtotal td {
    background: rgba(51, 105, 255, 0.12);
    font-weight: bold;
}

.card-package .table .subtotal td {
    background: rgba(51, 105, 255, 0.12);
}

.content-header-m0 .right-title-box {
    margin-bottom: 0px;
}

.summary-downline-heading {
    display: flex;
    align-items: center;
    padding: 15px 30px;
    background: linear-gradient(180deg,rgba(51,105,255,.24) -6.16%,rgba(27,42,84,.24) 110.96%);
    box-shadow: 0 4px 7px rgba(0,0,0,.3), inset 0 1.5px 0.5px hsla(0,0%,100%,.1);
    border-radius: 8px;
    column-gap: 30px;
}

    .summary-downline-heading .custom-icon-box {
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .summary-downline-heading .title {
        font-size: 14px;
    }

        .summary-downline-heading .title a {
            color: #3369FF;
        }

    .summary-downline-heading .date {
        font-size: 10px;
    }

#summary-downline-section .card-wrapper {
    padding: 0px;
    background: transparent;
    width: 100%;
    text-align: center;
}

#summary-downline-section .table {
    color: black;
    font-size: 12px;
}

    #summary-downline-section .table .grandtotal td:first-child {
        border-bottom-left-radius: 5px;
    }

    #summary-downline-section .table .grandtotal td:last-child {
        border-bottom-right-radius: 5px;
    }

.shareholder-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    cursor: pointer;
}

.referral-add-minus {
    /*  background: transparent;
  border: 1px solid #898F97;*/
    background: rgb(39,54,126);
    background: linear-gradient(260deg, rgba(39,54,126,1) 0%, rgba(36,47,100,1) 100%);
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.3) !important;
    height: 40px;
    padding: 0 40px;
    font-size: 14px;
}

.shareholder-box .badge.badge-danger {
    width: 100px;
    height: 25px;
}

.approved {
    background: linear-gradient(318.37deg, rgba(50, 249, 0, 0.8) 0%, rgba(20, 239, 199, 0.8) 94.77%), linear-gradient(0deg, rgba(164, 164, 164, 0.2), rgba(164, 164, 164, 0.2));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.rejected {
    color: #FF334B;
}

.vip-claim-history-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    cursor: pointer;
    padding: 15px 30px;
}

.has-downline {
    text-decoration: underline;
    cursor: pointer;
    color: #3369FF;
}
/* //end vip events/// */

/* ///gamepoint/// */
.gamepoint-header .title {
    font-size: 12px;
    color: #fff;
}

.gamepoint-header .details {
    font-size: 15px;
    color: #3369FF;
}

.gamepoint-total-box .details {
    font-size: 12px;
    color: #fff;
}

.gamepoint-redemption-item {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    padding: 15px;
    box-shadow: 0px 4px 7px 0px #0000004D;
    border-radius: 8px;
    height: 100%;
}

    .gamepoint-redemption-item .title-box {
        display: flex;
        align-items: self-start;
        justify-content: space-between;
    }

        .gamepoint-redemption-item .title-box .title {
            font-size: 14px;
        }

        .gamepoint-redemption-item .title-box .date {
            font-size: 10px;
            color: #898F97;
        }

    .gamepoint-redemption-item .amount {
        font-size: 12px;
        color: #3369FF;
    }

.btn-custom-small {
    font-size: 13px;
    background: #3369FF;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    cursor: pointer;
    padding: 10px 15px;
    position: relative;
    text-align: center;
    color: #fff;
    width: 100%;
}

.gamepoint-banner {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    padding: 15px;
    box-shadow: 0px 4px 7px 0px #0000004D;
    border-radius: 8px;
}

    .gamepoint-banner img {
        border-radius: 15px;
    }
/* ///end gamepoint/// */


/* ////landing/// */
#landing-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.landing-image {
    margin-bottom: 10em;
    text-align: center;
}

.landing-language-container {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 50px;
}

    .landing-language-container .title {
        color: #EBEBF599;
        font-size: 14px;
        margin-top: 10px;
    }

.landing-version {
    font-size: 12px;
    color: #898F97;
}

.landing-language-container .image {
    padding: 3px;
}

.landing-language-container .active .image {
    border: 1px solid #3369FF;
    border-radius: 100px;
    padding: 3px;
}

.landing-language-container .active .title {
    color: #3369FF;
}

.header-notification-icon {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

    .header-notification-icon .header-notification-dot {
        height: 6px;
        width: 6px;
        background: #FF334B;
        box-shadow: 0px 0px 5px 0px #FF334BCC;
        border-radius: 100px;
        position: absolute;
        top: 8px;
        right: 9px;
    }

    .header-notification-icon img {
        width: 27px;
        height: 27px;
    }

.header-wallet-container {
    display: flex;
    align-items: center;
    border: 1.5px solid #3369FF;
    box-shadow: 0px 4px 7px 0px #00000040;
    border-radius: 8px;
    padding: 5px 10px;
    column-gap: 12px;
    background: rgba(23, 41, 91, 1);
}

    .header-wallet-container .header-balance {
        display: flex;
        align-items: center;
        column-gap: 12px;
    }

        .header-wallet-container .header-balance .first {
            line-height: 0;
        }

            .header-wallet-container .header-balance .first img {
                height: 33px;
            }

        .header-wallet-container .header-balance .second {
            color: #3369FF;
        }

        .header-wallet-container .header-balance .third {
            font-size: 13px;
            line-height: 0;
        }

.right-button-two {
    justify-content: space-between;
    cursor: pointer;
}

    .right-button-two .quantity {
        color: #3369FF;
        font-size: 20px;
    }

.right-custom-redemption-item {
    display: flex;
    align-items: center;
    column-gap: 15px;
    border-bottom: 1px solid #D9D9D933;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

    .right-custom-redemption-item .title {
        font-size: 12px;
    }

    .right-custom-redemption-item .date {
        font-size: 9px;
        color: #898F97;
    }

.right-floating-icon-box {
    display: none;
}
/* ///end landing//// */



/* /////////add css after this comment not under the media queries/// */


/* /////////add css before this comment not under the media queries/// */

/* /////////media queries///// */
@media (max-width: 1500px) {
    .wallet-list-container > div {
        column-gap: 10px;
    }

        .wallet-list-container > div .value {
            font-size: 12px;
        }
}

@media (max-width: 1400px) {
    .home-left-wallet-item {
        padding: 40px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .home-games-grid-title {
        padding: 0px 5px;
    }

    .video-tutorial-content .video-name {
        font-size: 12px;
    }

    .with-information-container > .right {
        width: 250px;
    }

    .with-information-container > .left {
        width: calc(100% - 280px);
    }

    .right-custom-recent-box .first .title {
        font-size: 11px;
    }

    .right-custom-recent-box .first .date {
        font-size: 8px;
    }

    .right-custom-recent-box .first {
        column-gap: 7px;
    }

    .right-custom-recent-box .second {
        font-size: 11px;
    }

    .right-custom-game-item .first .title {
        font-size: 11px;
    }

    .right-custom-game-item > .first > div:first-child {
        width: 40px;
    }

    .filter-with-scroll-box .filter-items a {
        font-size: 12px;
        width: 100px;
    }

    .transac-history-search-box .th-date-search {
        font-size: 12px;
    }

    .transac-history-search-box {
        height: 40px;
    }

        .transac-history-search-box .th-search-btn {
            width: 22px;
        }

        .transac-history-search-box .date-filter-divider {
            margin-right: 47px;
        }

    #downlinetableform .edit-downline-table .data-row .row-data-1 {
        min-width: 90px;
    }
}

@media (max-width: 1199px) {
    #header-v3 .search-box input {
        width: 250px;
        min-width: unset;
    }

    .login-btn, .register-btn {
        font-size: 12px;
        min-width: 90px;
    }

    .theme-container .first-container {
        width: 220px;
        /* min-width: 220px; */
    }

    #side-menu {
        padding: 30px 10px;
    }

    .side-menu-top .title {
        font-size: 14px;
    }

    .side-menu-top.login .title {
        font-size: 12px;
    }

    .side-menu-item > div:first-child {
        width: 30px;
        min-width: 30px;
    }

    .side-menu-item {
        font-size: 12px;
    }

    .logout-button {
        font-size: 12px;
    }

    .side-menu-login-register {
        font-size: 12px;
    }

    #header-v3 .container {
        width: 97%;
        max-width: unset;
    }

    .container-theme-width {
        width: 97%;
        max-width: unset;
    }

    .theme-container .second-container {
        width: calc(100% - 250px);
    }

    .side-menu-top.login {
        column-gap: 15px;
    }

    .home-categories .home-game-category-label-left {
        font-size: 16px;
    }

    .home-categories .home-game-category-label-right {
        font-size: 16px;
    }

    .title-themed h4 {
        font-size: 20px;
    }

    .title-themed p {
        font-size: 12px;
    }

    .gamelist-title {
        font-size: 12px;
    }

    .home-games-grid-title {
        font-size: 12px;
    }

    .home-games-grid-provider {
        font-size: 12px;
    }

    .home-games-grid-container {
        grid-template-columns: repeat( auto-fit, minmax(20%, 1fr) );
    }

    .footer-top-container {
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(45%, 1fr) );
        grid-gap: 40px;
    }

    #custom-modal {
        width: 100%;
        max-width: 750px;
    }

    .modal-left-side {
        max-height: 532px;
    }

    .modal-title h2 {
        font-size: 25px;
    }

    .custom-input {
        height: 45px;
    }

    .custom-pin-container input {
        width: 50px !important;
    }

    .login-tac-modal .custom-pin-container input {
        width: 50px !important;
    }

    .with-information-container .right-information-component-container {
        display: none;
    }

    .with-information-container > .left {
        width: 100%;
    }

    #custom-modal-small.right-custom-modal .modal-content {
        border-radius: 8px;
    }

    #custom-modal-small.right-custom-modal {
        max-width: 350px;
        margin-right: 110px;
    }

    .with-information-container > .right {
        width: auto;
    }

    .with-information-container {
        column-gap: 0px;
    }

    .sort-search-box > div:last-child {
        max-width: 200px;
    }

    .right-floating-icon-box {
        position: fixed;
        bottom: 8%;
        right: 20px;
        z-index: 9999999999999999999;
        background-color: #2e2664;
        box-shadow: 0px 4px 7px 0px #0000004D;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
    }

        .right-floating-icon-box.active {
            background: linear-gradient(180deg, #2E57C9 0%, #04105C 100%);
        }
    /* #custom-modal-small.right-custom-modal .right-information-box{
        background: #1E2A6B;
    } */
}

@media (max-width: 991px) {
    .home-left-wallet-container {
        margin-bottom: 30px;
    }

    .home-right-wallet-container {
        padding: 30px;
    }

    #header-v3 .search-box input {
        width: 185px;
    }

    .theme-container .first-container {
        width: 190px;
        min-width: 190px;
    }

    .theme-container .second-container {
        width: calc(100% - 220px);
    }

    .side-menu-item {
        column-gap: 10px;
    }

    footer .f-links {
        text-align: center;
    }

    .home-categories .home-top-categories {
        column-gap: 15px;
    }

    .after-home-category {
        margin-top: 15px;
    }

    footer .f-links li a:after {
        margin-right: 7px;
        padding-right: 7px;
    }

    footer a {
        font-size: 12px;
    }

    .home-subscribe-button h2 {
        font-size: 20px;
    }

    .home-subscribe-button p {
        font-size: 14px;
    }

    .notification-container h4 {
        font-size: 16px;
    }

    .notification-container > .first {
        column-gap: 15px;
    }

    .contactus-list {
        padding: 10px 15px;
    }

        .contactus-list .channel-content {
            column-gap: 15px;
        }

    .custom-box-list .custom-item-box .title {
        font-size: 14px;
    }

    .custom-box-list .custom-item-box .date {
        font-size: 10px;
    }

    .custom-box-list .custom-item-box {
        padding: 10px 20px;
    }

        .custom-box-list .custom-item-box > .first {
            column-gap: 15px;
        }

    .video-tutorial-content .video-provider {
        font-size: 10px;
    }

    .video-tutorial-content .first {
        column-gap: 15px;
    }

    .video-tutorial-content {
        column-gap: 15px;
    }

    .custom-description-container {
        font-size: 12px;
    }

        .custom-description-container h3 {
            font-size: 14px;
        }

        .custom-description-container u {
            font-size: 14px;
        }

        .custom-description-container .table-bordered td span {
            font-size: 10px;
        }

    .wallet-list-container {
        grid-template-columns: repeat( auto-fit, minmax(40%, 1fr) );
    }

    #downlinetableform .edit-downline-table .data-row.category {
        column-gap: 15px;
    }

    #downline-group-section #downline-group-box .title {
        font-size: 12px;
    }

    .kpi-grid .kpi-title {
        font-size: 12px;
    }

    .kpi-grid .kpi-details {
        font-size: 10px;
    }

    .custom-box-with-icon-container {
        font-size: 12px;
    }

    #custom-modal-small.right-custom-modal .modal-content {
        border-radius: 8px;
        position: absolute;
        bottom: 9.5%;
    }

    #custom-modal-small.right-custom-modal {
        max-width: 350px;
        margin-right: 110px;
        height: 100%;
    }
}

@media (max-width: 767px) {
    .home-categories .home-game-category-label-left {
        font-size: 12px;
    }

    .home-categories .home-game-category-label-right {
        font-size: 12px;
    }

    .home-games-grid-container {
        grid-template-columns: repeat( auto-fit, minmax(25%, 1fr) );
    }

    .home-left-wallet-item {
        padding: 20px 15px;
    }
}
/* /////////media queries///// */
.offline-message {
    color: white;
    text-align: center;
    padding: 20px 60px;
}

.transaction-pin-modal {
    padding: 40px 20px 40px;
    max-width: 500px;
    margin: auto;
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(242, 1, 29, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%), linear-gradient(180deg, rgba(78, 75, 200, 0.2) 0%, rgba(0, 84, 182, 0) 100%);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(35px);
    border-radius: 65px 65px 65px 65px;
    border: 0px;
    width: 100%;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

.transaction-pin {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.custom-cancel-btn {
    background: #FF334B;
    box-shadow: 0px 5px 30px rgba(51, 105, 255, 0.7);
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    width: 100%;
    padding: 0px 15px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-category-dropdown {
    border-width: 0px !important;
}

.confirm-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 20px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

.confirm-btn-div {
    display: flex;
    align-items: center;
    flex-direction: row;
    grid-column-gap: 20px;
}

.custom-edit-downline {
    background: #3369FF;
    border: unset;
    /*    box-shadow: 0px 5px 30px rgba(51, 105, 255, 0.7);*/
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    width: 100%;
    padding: 0px 15px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.downline-main-boxes-ul {
    border-radius: 8px;
    padding: 15px 0px;
}

.downline-accordion .accordion-button {
    background: transparent;
    border-radius: 8px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 0px;
    font-size: 14px;
}
    .downline-accordion .accordion-button:focus-visible {
        outline: none;
    }

    .downline-accordion .accordion-button[aria-expanded="true"] {
        background: transparent;
        box-shadow: unset;
        border-radius: 8px 8px 0px 0px;
    }

.downline-accordion .accordion-body:before {
    display: block;
    content: ' ';
    width: calc(100% - 20px);
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    margin: auto;
    margin-bottom: 15px;
}

.downline-accordion .accordion-item {
    border-radius: 8px;
    background: white;/*radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(242, 1, 29, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%), linear-gradient(180deg, rgba(78, 75, 200, 0.2) 0%, rgba(0, 84, 182, 0) 100%);*/
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
}

.downline-accordion h2 {
    margin-bottom: 0px;
}

.ul-news-list.custom-dynamic-table-container .swipeable-list {
    height: unset;
}

    .ul-news-list.custom-dynamic-table-container .swipeable-list .swipeable-list-item__content {
        padding: 12px 20px;
    }

.ul-news-list.custom-dynamic-table-container .swipeable-list-item__trailing-actions {
    min-width: 75px;
    height: 75px;
    max-height: unset;
    margin: unset;
}

.ul-news-list.custom-dynamic-table-container .align-items-center {
    padding: 0.5rem;
    width: 326px;
}

.downlink-detail-text {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    padding-left: 10px;
    color: black;
}

.downline-group-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
}

.background-div {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%) !important;
}

.phone-number-verified-div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    grid-row-gap: 20px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    width: 100%;
}

.phone-number-text {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(164, 164, 164, 1);
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.birthdate-custom-input {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    border: 0;
    box-shadow: 0px 4px 7px 0px #0000004D;
    border-radius: 8px;
    width: 100%;
    font-size: 14px;
    color: #898F97;
    height: 50px;
    padding: 0px 20px 0px 25px;
}

    .birthdate-custom-input::placeholder {
        color: #898F97 !important;
    }

    .birthdate-custom-input:-ms-input-placeholder {
        color: #898F97 !important;
    }

.hide-summary-box {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.vip-event-downline-data {
    border-radius: 0px 0px 8px 8px;
    background: transparent;
}

.shareholder-data {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(137, 143, 151, 1);
}

.edit-sh-btn {
    text-align: center;
    padding: 10px 0;
    color: rgba(51, 105, 255, 1);
    cursor: pointer;
}

.sh-select {
    border: 0px solid #898F97;
    background: transparent;
    outline: unset !important;
    color: #3369FF;
    font-size: 12px;
}

.sh-min-max {
    color: #FF334B;
    font-size: 11px;
}

.sh-select-box {
    border: 1px solid #898F97;
    padding: 5px 10px;
    border-radius: 100px;
}

.shareholder-hide-summary {
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
}

.shareholder-data-detail {
    display: flex;
    justify-content: flex-start;
    padding: 10px 0px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    /*color: rgba(137, 143, 151, 1);*/
    color: white;
}

.claim-btn-disable {
    color: #fff;
    border-radius: 20px;
    padding: 5px 30px;
    border: 0px;
    font-size: 12px;
}

.recommended-match-container {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 40px;
    cursor: pointer;
    font-family: Montserrat;
}


.recommended-match-details {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/img/v3/selected_recommended_match_background.png");
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 200px;
}

    .recommended-match-details .topbar {
        font-size: 12px;
        color: white;
        display: grid;
        padding: 10px 20px;
        grid-template-columns: 35% 30% 35%;
    }

        .recommended-match-details .topbar .vendor-logo img {
            width: 70px;
            height: 15px;
        }

        .recommended-match-details .topbar .date-time {
            text-align: end;
        }

    .recommended-match-details .live {
        text-align: center;
        font-size: 14px;
    }

        .recommended-match-details .live .dot {
            height: 10px;
            width: 10px;
            background-color: red;
            border-radius: 50%;
            display: inline-block;
            margin-right: 5px;
        }

    .recommended-match-details .match {
        display: grid;
        grid-template-columns: 40% 20% 40%;
        text-align: center;
        margin-top: 15px;
        color: white;
    }

        .recommended-match-details .match .team-logo img {
            width: 60px;
            height: 60px;
        }

        .recommended-match-details .match .team-logo .team-name {
            text-align: center;
            font-size: 12px;
            display: block;
            margin: 5px 0px;
        }

        .recommended-match-details .match .team-logo .team-score {
            text-align: center;
            font-size: 20px;
            color: #FFD928;
            display: block;
            margin: 5px 0px;
        }

        .recommended-match-details .match .versus {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
        }

.recommended-match-button {
    color: white;
    text-align: center;
    background-color: #3369FF;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 100%;
    padding: 10px;
    border: 0px;
}




.live-chat-box {
    width: 80%;
    position: relative;
    margin: auto;
    text-align: center;
}

.live-chat-img-button {
    width: 75%;
    height: 75%;
}

.live-chat-modal-container {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgb(25 35 47)), linear-gradient(271.33deg, rgb(78 75 200 / 69%) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    padding: 25px 25px;
    border-radius: 8px;
    margin-top: 12px !important;
}

#custom-modal3 {
    align-items: flex-end;
    height: 100vh;
    margin: 0 !important;
    text-align: center;
}

    #custom-modal3 .modal-content {
        background: transparent;
        box-shadow: unset;
        max-height: 90%;
        padding-left: 58%;
        padding-bottom: 1%;
    }

        #custom-modal3 .modal-content::-webkit-scrollbar {
            display: none;
        }

    #custom-modal3 .modal-body {
        padding: 40px 20px 40px;
        max-width: 500px;
        margin: auto;
        background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(255, 51, 75, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%);
        -webkit-backdrop-filter: blur(35px);
        backdrop-filter: blur(35px);
        border-radius: 20px 20px 0px 20px;
        border: 0px;
        width: 100%;
    }

.live-chat {
    position: fixed;
    z-index: 15;
    right: 0;
    bottom: 0;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: #000000c4;
    backdrop-filter: blur(2px);
    background-color: dodgerblue;
    height: 65px;
    width: 65px;
    border-radius: 50%
}

.modal-bottom-live-chat {
    position: fixed;
    left: 0;
    bottom: 80px;
    max-height: 100%;
    overflow: auto;
    width: 95%;
}

.live-chat-grid {
    display: flex;
    justify-content: space-between;
    background-color: blue;
}

.live-chat-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-items: center;
    grid-row-gap: 20px;
}

.live-chat-item {
    background-color: #f0f0f0;
    padding: 20px;
    text-align: center;
}

.live-chat-gird {
    border-radius: 50%;
    background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 50px;
    max-width: 50px;
}

.live-chat-channel-name {
    color: white;
    display: flex;
    justify-items: center;
    align-items: center;
}

.dark-bg-live-chat {
    height: 1000vh;
    width: 100%;
    background: #000000a8;
    backdrop-filter: blur(5px);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 10
}

.live-chat-button-container {
    position: absolute;
    left: -363px;
    top: -320px;
}

.live-chat-button-description {
    font-size: 10px;
    color: white !important;
    display: flex;
    justify-content: center;
}

.live-chat-icon {
    display: grid;
    justify-items: center;
}





.spin-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.spin-box {
    max-width: 610px;
    min-width: 610px;
    margin: auto;
    position: relative;
    width: 100%;
}

.img-responsive {
    max-width: 100%;
    display: inline-block;
}

.w-100 {
    width: 100%;
}

.spin-bottom {
    position: absolute;
    bottom: -230px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    z-index: 4;
}

.spin-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background: transparent;
    border: 0px;
}

    .spin-icon > img {
        max-width: 90%;
    }
/* .spin-icon>img{
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
} */
.spin-frame {
    position: relative;
    z-index: 1;
}

.not-spinning .spin-list-background {
    position: absolute;
    left: 50%;
    top: 21%;
    transform: translate(-50%,0);
    width: 100%;
    text-align: center;
    width: 77%;
}

.not-spinning .prize-name-list {
    position: absolute;
    width: 100%;
    height: 80%;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    left: 50%;
    top: 9%;
    transform: translate(-50%,0);
    padding: 0px !important;
    margin: 0px !important;
}

    .not-spinning .prize-name-list li {
        position: absolute;
        top: 0;
        transform-origin: 50% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50%;
        font-size: 18px;
        color: #fff;
        text-align: center;
        padding-bottom: 6%;
    }

.spinning .spin-list-box {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: absolute;
    left: 11.5%;
    top: 21%;
    transform: translate(-50%,0);
    width: 77%;
}

.spinning .prize-name-list {
    position: absolute;
    width: 100%;
    height: 80%;
    border-radius: 50%;
    overflow: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    left: 50%;
    top: -8%;
    transform: translate(-50%,0);
    padding: 0px !important;
    margin: 0px !important;
}

    .spinning .prize-name-list li {
        position: absolute;
        top: 0;
        transform-origin: 50% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50%;
        font-size: 18px;
        color: #fff;
        text-align: center;
        padding-bottom: 17%;
    }

.icon1 {
    position: absolute;
    left: -18%;
}

.icon2 {
    position: absolute;
    right: -18%;
}

.icon3 {
    position: absolute;
    width: calc(4% * 8);
    animation: iconFloater 15s infinite;
    transition: ease-in-out .6s;
    z-index: 5;
    background: unset;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: calc(0.8% * 8);
    bottom: -6%;
    right: -19%;
}

.icon4 {
    position: absolute;
    width: calc(4% * 8);
    animation: iconFloater 15s infinite;
    transition: ease-in-out .6s;
    z-index: 5;
    background: unset;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: calc(0.8% * 8);
    bottom: -8%;
    left: -15%;
}

.icon5 {
    position: absolute;
    width: calc(4% * 5);
    animation: iconFloater 15s infinite;
    transition: ease-in-out 0.6s;
    z-index: 5;
    background: unset;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: calc(0.8% * 5);
    top: 40%;
    left: -25%;
}

.icon6 {
    position: absolute;
    width: calc(4% * 3);
    animation: iconFloater 15s infinite;
    transition: ease-in-out 0.6s;
    z-index: 5;
    background: unset;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: calc(0.8% * 3);
    bottom: 34%;
    right: -18%;
}

.d-none {
    display: none;
}

body {
    margin: 0px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes iconFloater {
    0% {
        transform: translateY(0%);
    }

    25% {
        transform: translateY(1em);
    }
}

.rainbow-btn {
    /* margin: 50px auto; */
    width: 60%;
    text-align: center;
    position: relative;
    color: #ffffff;
    text-decoration: none;
    border-radius: 6px;
    box-sizing: border-box;
    display: block;
    z-index: 2;
    overflow: hidden;
    padding: 4px;
    height: 40px;
    margin: auto;
    position: absolute;
    top: 3%;
    z-index: 5;
    left: 50%;
    transform: translate(-50%, 0);
}

    .rainbow-btn:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 100%;
        background: linear-gradient(115deg,#3c0ce9,#485afa,#3143ec,#12bcfe,#44a2ce);
        background-size: 50% 100%
    }

    .rainbow-btn span {
        position: relative;
        z-index: 2;
        padding: 5px 0;
        font-size: 1.1rem;
        text-decoration: none;
        align-items: center;
        background: #a56820;
        border-radius: 3px;
        display: block;
        justify-content: center;
        box-sizing: border-box;
        height: 100%;
        font-size: 15px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }

    .rainbow-btn:focus:before,
    .rainbow-btn:hover:before {
        animation: rainbow-btn .75s linear infinite
    }

@keyframes rainbow-btn {
    to {
        transform: translateX(-50%)
    }
}

/* //new added */
.i8-spin-section {
    text-align: center;
    padding-top: 119px;
    position: relative;
}

.i8-spin-ring {
    position: absolute;
    z-index: 5;
    top: 27px;
    left: -18px;
}

.i8-spin-body {
    width: 350px;
    height: 350px;
    display: inline-block;
    /* animation-name: spin; */
    animation-duration: 5000ms;
    position: relative;
    z-index: 10;
}

.i8-spin-arrow {
    position: absolute;
    width: 82px;
    bottom: 36%;
    right: 20%;
    z-index: 16;
    opacity: .6;
}

.text-b {
    color: #08174f;
}

.text-y {
    color: #f4ee00;
}

.i8-spin-body > div {
    position: absolute;
    font-weight: bold;
    z-index: 4;
    font-size: 25px;
    width: 92px;
}

/* ////with image item/// */
.i8-spin-body .rewards {
    top: 0%;
    right: 37%;
    transform-origin: bottom;
}

.i8-spin-body .i8-spin-body-frame {
    top: 0px;
    width: 190px;
}

.i8-spin-body .i8-spin-body-frame {
    top: 0px;
    width: 350px;
}


.data1 {
    top: 13.7%;
    right: 24%;
    transform: rotate(-59deg);
}

.data2 {
    top: 31%;
    right: 6.3%;
    transform: rotate(-30deg);
    font-size: 12px !important;
}

.data3 {
    top: 44.7%;
    right: 8.3%;
}

.data4 {
    top: 61.7%;
    right: 15%;
    transform: rotate(30deg);
}

.data5 {
    top: 73.7%;
    right: 28%;
    transform: rotate(55deg);
}

.data6 {
    top: 77.7%;
    left: 45.3%;
    transform: rotate(90deg);
}

.data7 {
    top: 75%;
    left: 20.3%;
    transform: rotate(-243deg);
    font-size: 17px !important;
}

.data8 {
    top: 61%;
    left: 11.6%;
    transform: rotate(-210deg);
}

.data9 {
    top: 47.5%;
    left: 4%;
    transform: rotate(-181deg);
    font-size: 14px !important;
}

.data10 {
    top: 27%;
    left: 15.6%;
    transform: rotate(-150deg);
    letter-spacing: 2px;
}

.data11 {
    top: 12.2%;
    left: 28%;
    transform: rotate(-120deg);
    letter-spacing: 2px;
}

.data12 {
    top: 10%;
    left: 45%;
    transform: rotate(-84deg);
}

.spinwheel-winning-popup-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*.spinwheel-winning-img {
    width: 100%;
    text-align: center;
    height: 100px;
}
    .spinwheel-winning-img img {
        height: 100%;
    }*/

.spinwheel-winning-btn {
    box-shadow: 0px 5px 30px #3369ffb3;
    margin-top: 10px;
}

    .spinwheel-winning-btn .custom-btn {
        width: 100%;
        border: none;
        background: #4274ff;
        color: white;
        font-size: 20px;
        padding: 5px 10px;
    }


.spin-container-bg-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Adjust the opacity by changing the last value (0.5 in this case) */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}


.i8-bottom {
    position: relative;
    z-index: 15;
    margin-top: -20px !important;
    max-width: 400px;
    margin: auto;
}

.i8-bottom-button {
    position: relative;
    z-index: 10;
    margin-top: -10px !important;
    max-width: 511px;
    margin: auto;
    cursor: pointer;
}

.i8-bottom-left-coin {
    position: absolute;
    z-index: 15;
    width: 81px;
    height: 67px;
    left: 130px;
    top: 470px;
    animation: floatUpDown 2s ease-in-out infinite;
}

.i8-bottom-right-coin {
    position: absolute;
    z-index: 5;
    width: 78px;
    height: 83px;
    top: 470px;
    right: 130px;
    animation: floatUpDown2 2s ease-in-out infinite;
}

.spin-container-close {
    position: absolute;
    top: 50px;
    right: 50px;
    z-index: 999;
    cursor: pointer;
}

@keyframes floatUpDown {
    0%, 100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-5px);
    }
}

@keyframes floatUpDown {
    0%, 100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-5px);
    }
}

@keyframes floatUpDown2 {
    0%, 100% {
        transform: translateY(-5px);
    }

    50% {
        transform: translateY(0);
    }
}

.spin-container-open {
    position: fixed;
    z-index: 10;
    left: 94%;
    bottom: 12%;
    transform: translate(-50%,-50%);
    display: flex;
    align-items: center;
    height: 100px;
    width: 100px;
    background: url('/img/version3/spinwheel/lucky-wheel-icon.png') no-repeat transparent;
    background-size: contain;
    cursor: pointer;
    animation: popOut 2s ease-in-out infinite;
}


@keyframes popOut {
    0%, 50%, 100% {
        transform: scale(1) translate(-50%,-50%);
    }

    25%, 75% {
        transform: scale(1.1) translate(-50%,-50%);
    }
}

.i8-bottom-remainder {
    position: absolute;
    right: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 70px;
    line-height: 82px;
    top: 0;
    text-align: center;
    width: 102px;
    font-family: "Roboto", sans-serif;
}



#success-modal {
    padding: 0px 20px;
}

    #success-modal .modal-content {
        color: #ffffff;
        border-radius: 30px;
        overflow: hidden;
        background-color: #2c2c2c;
    }

    #success-modal .modal-body {
        /*  background: linear-gradient(0deg, rgba(36, 80, 202, 0.5) 0%, rgb(248 230 255 / 10%) 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));*/
        background: #04102a;
    }


/*.loading-overlay {
    display: none;
}

._loading_overlay_wrapper--active.loading-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffffcf;
    z-index: 99999;*/
/* z-index: 99; */
/*}

._loading_overlay_overlay {
    display: none !important;
}

._loading_overlay_wrapper--active.loading-overlay p {
    color: #1c2a4f;
    margin-top: 1rem;
}

.loading-overlay.hide {
    display: none !important
}

.loading-overlay img {
    width: 50px;
}*/

.custom-overlay {
    background: red;
}

.daily-big-div {
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
    height: 100%;
    justify-content: flex-start;
}

.daily-check-in {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    grid-column-gap: 20px;
    justify-content: space-evenly;
}

.daily-check-in-div {
    background-color: rgba(16, 33, 86, 1);
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 60px;
    top: 138px;
    left: 12px;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    color: rgba(255, 255, 255, 0.87);
    cursor: pointer;
}

.daily-check-in-div-1 {
    background-color: rgba(16, 33, 86, 1);
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 60px;
    top: 138px;
    left: 12px;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 5%;
    color: rgba(255, 255, 255, 0.87);
    grid-column-gap: 30px;
}

.daily-div {
    display: flex;
    align-items: center;
    flex-direction: row;
    grid-column-gap: 30px;
}

.check-in-big-div {
    background-color: #02214a;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 60px;
    top: 138px;
    left: 12px;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    color: rgba(255, 255, 255, 0.87);
    cursor: pointer;
}

.check-in-click {
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    background-color: rgba(16, 33, 86, 1);
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    top: 138px;
    left: 12px;
    border-radius: 8px;
    padding: 15px 15px;
    color: rgba(255, 255, 255, 0.87);
}

.div-togerther {
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-checkin {
    position: absolute;
}

.img-div {
    position: absolute;
    background-color: #02214a;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 60px;
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.87);
}

.img-coin {
    width: 50px;
    padding-bottom: 10px;
}

.div-check-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gallery {
   /* margin: 20px 0px 0px 20px;
    float: left;
    width: 10%;*/
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.3);
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90px;
    height: 110px;
   /* top: 497px;
    left: 624.55px;*/
    border-radius: 5px;
    position: sticky;
}

    .gallery img {
        width: 50%;
        height: auto;
        padding-top: 25px;
    }

.point-div {
    background: linear-gradient(90deg, #295EAC 0%, #314F7D 100%);
    box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
    width: 80%;
    height: 15px;
    border-radius: 8px 8px 0px 0px;
    color: rgba(255, 255, 255, 0.87);
    /* padding: 5px; */
    text-align: center;
    font-size: 11px;
}

.desc-div {
    background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
    box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
    width: 100%;
    height: 30px;
    border-radius: 0px 0px 8px 8px;
    color: rgba(255, 255, 255, 0.87);
    padding: 5px;
    text-align: center;
    margin-block-start: auto;
    font-size: 13px;
    font-weight: bold;
}

.daily-check-in-day {
    display: grid;
    justify-content: center;
    /*grid-column-gap: 10px;*/
    padding: 20px 0;
    /* flex-direction: row;
    flex-wrap: wrap;*/
    justify-items: center;
    grid-template-columns: repeat(7, 110px);
    align-items: center;
}

.gallery-check-in {
   /* margin: 20px 0px 0px 20px;
    float: left;
    width: 10%;*/
    background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
    box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
    border: 1.5px solid rgba(51, 105, 255, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90px;
    height: 110px;
    /*top: 497px;
    left: 624.55px;*/
    border-radius: 5px;
    position: sticky;
}

    .gallery-check-in img {
        padding-top: 50%;
        width: 40px;
    }

.check-in-button {
    width: 100%;
    height: 40px;
    top: 315px;
    left: 22px;
    border-radius: 8px;
    background: rgba(51, 105, 255, 1);
    border: unset;
}

.check-in-text {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
}

.check-in-text-disable {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0px;
    color: white;
}

.video-data {
    display: flex;
    justify-content: center;
    padding: 10px 20px;
}

.video-data-text {
    color: #898F97;
    font-size: 12px;
    font-weight: 400;
}

.days-today-text {
    color: rgba(51, 105, 255, 1) !important;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: center;
    align-self: center;
}



.redicon {
    position: absolute;
    background: linear-gradient(90deg, #295EAC 0%, #314F7D 100%);
    box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
    width: 75%;
    height: 15px;
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.87);
    text-align: center;
    font-size: 11px;
    top: -10px;
}

.redicon-text {
    height: 20px;
    color: rgba(255, 255, 255, 0.87);
    text-align: center;
    margin-block-start: auto;
    font-size: 13px;
}

.gallery-today {
   /* margin: 20px 0px 0px 20px;
    float: left;
    width: 10%;*/
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.3);
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90px;
    height: 110px;
    /*top: 497px;
    left: 624.55px;*/
    border-radius: 5px;
    position: sticky;
    border: 2px solid rgba(51, 105, 255, 1);
}

    .gallery-today img {
        width: 50%;
        height: auto;
        padding-top: 25px;
    }

    .gallery-today > .desc-div {
        background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
        box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
        width: 100%;
        height: 30px;
        border-radius: 0px 0px 8px 8px;
        color: #BACCFF;
        padding: 5px;
        text-align: center;
        margin-block-start: auto;
        font-size: 13px;
        font-weight: bold;
    }

.gallery-check-in-today {
   /* margin: 20px 0px 0px 20px;
    float: left;
    width: 10%;*/
    background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
    box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
    border: 2px solid rgba(51, 105, 255, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90px;
    height: 110px;
    /*top: 497px;
    left: 624.55px;*/
    border-radius: 5px;
    position: sticky;
}

    .gallery-check-in-today img {
        width: 50px;
        height: 75px;
        padding-top: 25px;
    }

    .gallery-check-in-today > .desc-div {
        background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
        box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
        width: 100%;
        height: 30px;
        border-radius: 0px 0px 8px 8px;
        color: rgba(51, 105, 255, 1);
        padding: 5px;
        text-align: center;
        margin-block-start: auto;
        font-size: 13px;
        font-weight: bold;
    }

@media (max-width: 1637px) {
    .daily-check-in-day {
        grid-column-gap: 5px;
    }
}

/*@media (max-width: 1580px) {
    .daily-check-in-day {
        grid-column-gap: 0px;
    }
}*/

@media (max-width: 1578px) {
    .daily-check-in-day {
        grid-column-gap: 0px;
    }
}

@media (max-width: 1518px) {
    .daily-check-in-day {
        grid-column-gap: 0px;
        grid-template-columns: repeat(7, 90px);
    }
}

@media (max-width: 1348px) {
    .daily-check-in-day {
        grid-column-gap: 0px;
    }
}

@media (max-width: 1564px) {
    .gallery {
        width: 85px;
        height: 105px;
    }

    .gallery-today {
        width: 85px;
        height: 105px;
    }

    .gallery-check-in {
        width: 85px;
        height: 105px;
    }
    .gallery-check-in-today {
        width: 85px;
        height: 105px;
    }
}

@media (max-width: 1529px) {
    .gallery {
        margin: 20px 0 0 10px;
    }

    .gallery-today {
        margin: 20px 0 0 10px;
    }

    .gallery-check-in {
        margin: 20px 0 0 10px;
    }

    .gallery-check-in-today {
        margin: 20px 0 0 10px;
    }
}

@media (max-width: 1473px) {
    .gallery {
        margin: 20px 0 0 5px;
    }

    .gallery-today {
        margin: 20px 0 0 5px;
    }

    .gallery-check-in {
        margin: 20px 0 0 5px;
    }

    .gallery-check-in-today {
        margin: 20px 0 0 5px;
    }
}

@media (max-width: 1330px) {
    .daily-check-in-day {
        display:flex;
    }
}

@media (max-width: 1199px) {
    .daily-check-in-day {
        display: grid;
        grid-template-columns: repeat(7, 110px);
    }
}


@media (max-width: 1096px) {
    .daily-check-in-day {
        /*grid-column-gap: 0px;*/
        grid-template-columns: repeat(7, 90px);
    }
}

@media (max-width: 1071px) {
    .daily-check-in-day {
        grid-column-gap: 0px;
        /*grid-template-columns: repeat(7, 80px);*/
    }
}

@media (max-width: 1032px) {
    .daily-check-in-day {
        grid-column-gap: 0px;
    }
}

@media (max-width: 1032px) {
    .daily-check-in-day {
        display: flex;
    }
}

/*@media (max-width: 942px) {
    .daily-check-in-day {*/
        /*grid-column-gap: 20px;*/
    /*}
}*/
/*
@media (max-width: 1350px) {
    .gallery {
        margin: 20px 0 0 1px;
    }

    .gallery-today {
        margin: 20px 0 0 1px;
    }

    .gallery-check-in {
        margin: 20px 0 0 1px;
    }
}*/



.download-app-container {
    margin-bottom: 40px;
    text-align: center;
    border-radius: 10px;
    font-family: "Montserrat";
    color: white;
    display: flex;
    justify-content: space-between;
}

    .download-app-container .download-app-container-left {
        width: 40%;
        padding: 20px;
    }

        .download-app-container .download-app-container-left .download-app-header {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 40px;
        }

        .download-app-container .download-app-container-left .download-app-description {
            font-size: 16px;
            margin-bottom: 40px;
        }

        .download-app-container .download-app-container-left .download-app-link {
            display: flex;
            align-items: center;
            justify-content: space-around;
        }

            .download-app-container .download-app-container-left .download-app-link .download-app-qr-section {
                margin: 0px 10px;
            }

                .download-app-container .download-app-container-left .download-app-link .download-app-qr-section .download-app-qr {
                    background: white;
                    border-radius: 10px;
                    margin-bottom: 10px;
                    padding: 15px 15px 10px;
                }

                    .download-app-container .download-app-container-left .download-app-link .download-app-qr-section .download-app-qr canvas {
                        height: 100px !important;
                        width: 100px !important;
                    }

                .download-app-container .download-app-container-left .download-app-link .download-app-qr-section .download-app-button {
                    background: white;
                    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
                    box-sizing: border-box;
                    border-radius: 50px;
                    font-size: 12px;
                    text-align: center;
                    display: flex;
                    padding: 5px 20px;
                    color: #ffffffb4;
                    font-family: "Montserrat";
                    text-transform: unset;
                    width: 100%;
                    cursor: pointer;
                    color: black;
                    align-items: center;
                    justify-content: center;
                    gap: 4px;
                }

                    .download-app-container .download-app-container-left .download-app-link .download-app-qr-section .download-app-button .download-text-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 20px;
                    }

                    .download-app-container .download-app-container-left .download-app-link .download-app-qr-section .download-app-button img {
                        height: 20px;
                    }

    .download-app-container .download-app-container-right {
        width: 50%;
        padding: 0px 20px;
    }

        .download-app-container .download-app-container-right img {
            width: 100%
        }

.special-rollover-container {
    background: linear-gradient(to bottom, rgba(27, 42, 84, 0.24), rgba(51, 105, 255, 0.24));
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    padding: 15px 40px;
    border-radius: 8px;
}

    .special-rollover-container h1 {
        font-size: 15px;
        color: white;
    }

    .special-rollover-container .icon-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 3px;
    }

    .special-rollover-container .inner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 3px;
    }

        .special-rollover-container .inner-container.playable {
            background: linear-gradient(to bottom, rgba(27, 42, 84, 0.24), rgba(51, 105, 255, 0.24));
            box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
            border-radius: 18px;
            padding: 7px 14px;
        }

    .special-rollover-container img {
        width: 25px;
        height: 25px;
    }

    .special-rollover-container .inner-container img {
        opacity: 0.3;
    }

    .special-rollover-container .inner-container.playable img {
        opacity: 1;
    }

    .special-rollover-container .inner-container span {
        opacity: 0.3;
    }

    .special-rollover-container .inner-container.playable span {
        opacity: 1;
    }

    .special-rollover-container .progress-bar-container {
        margin-top: 12px;
    }

    .special-rollover-container .progress-bar-outline {
        padding: 2px;
        height: 100%;
        width: 100%;
        background: #404881;
        border-radius: 5px;
        position: relative;
    }

        .special-rollover-container .progress-bar-outline span {
            color: white;
            font-size: 15px;
            left: 50%;
            font-weight: 900;
        }

    .special-rollover-container .progress-bar-fill {
        background: #3369FF;
        border-radius: 5px;
        transition: width 0.5s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
    }

    .special-rollover-container .rollover-text {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        color: white;
        column-gap: 5px;
        font-weight: 700;
    }

.download-app-container .download-app-container-right img {
    width: 100%
}


.slideshow-div {
    padding-top: 50px;
    display: flex;
    width: 90%;
    grid-column-gap: 5%;
    justify-content: space-between;
    flex-direction: column;
}

.slideshow-container {
    width: 100%;
    position: relative;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
}
/*
.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}*/

.mobile-promo-slider .mobile-promo-banner .mobile-promo-img {
    width: 100%;
    border-radius: 8px;
}

.gallery-check-in-today > .desc-div {
    background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
    box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
    width: 100%;
    height: 30px;
    border-radius: 0px 0px 8px 8px;
    color: rgba(51, 105, 255, 1);
    padding: 5px;
    text-align: center;
    margin-block-start: auto;
    font-size: 13px;
    font-weight: bold;
}

.pb-5 hr {
    background: transparent;
    border-top: 2px solid #2D3C6B;
}

.loyalty-submit-button {
    width: 100%;
    height: 40px;
    top: 927px;
    left: 377px;
    border-radius: 8px;
    background: #3369FF;
    /*    box-shadow: 0px 5px 20px 0px #3369FFB2;*/
    color: white;
}

    .loyalty-submit-button .first {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translate(0, -50%);
    }

.loyalty-title-container {
    display: flex;
    flex-direction: column;
}

.loyalty-confirm-modal-title {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
}

    .loyalty-confirm-modal-title b {
        font-size: 17px;
    }

.loyalty-confirm-modal-item {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
}

#loyalty-confirm-modal .modal-content {
    /*background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(242, 1, 29, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%), linear-gradient(180deg, rgba(78, 75, 200, 0.2) 0%, rgba(0, 84, 182, 0) 100%);*/
    background: rgb(2,0,36);
    background: linear-gradient(270deg, rgba(2,0,36,1) 0%, rgba(34,31,73,1) 35%, rgba(18,16,37,1) 100%);
    border-radius: 50px;
    box-shadow: 0px 4px 20px 0px #00000066;
    padding: 30px;
}

#loyalty-confirm-modal h3 {
    font-size: 24px;
    font-weight: 400;
}

#loyalty-confirm-modal .ref-share {
    margin: auto;
    display: inline-block;
}

.loyalty-custom-btn {
    background: #3369FF;
    /*    box-shadow: 0px 5px 30px rgba(51, 105, 255, 0.7);*/
    border-radius: 15px;
    color: #fff;
    font-size: 18px;
    width: 100%;
    padding: 10px 15px;
}

.loyalty-confirm-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 20px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    width: 100%;
}

#header-search {
    background-color: inherit;
    border-left: 1px solid hsla(0,0%,79.6%,.14901960784313725);
    border-top: 1px solid hsla(0,0%,79.6%,.14901960784313725);
    border-bottom: 1px solid hsla(0,0%,79.6%,.14901960784313725);
    border-right: none;
    color: #fff;
    font-size: 11px;
    padding: 0 20px;
    width: 100%;
    height: 35px;
    font-weight: 300;
    font-family: Montserrat;
    margin-right: 1px;
}

.header-search-icon-video-tutorial {
    cursor: pointer;
}



.loyalty-confirm-btn-div {
    display: flex;
    align-items: center;
    flex-direction: row;
    grid-column-gap: 20px;
    width: 100%;
}

.game-pts-item-name {
    margin-left: 10px;
}

.game-pts-item-image {
    margin-right: 10px;
}

.game-pts-item-pointneeded {
    margin-left: 10px;
}


#video-tutorial-v3 .search-box-vt .input-group-text {
    background-color: inherit;
    border-right: 1px solid hsla(0,0%,79.6%,.14901960784313725);
    border-top: 1px solid hsla(0,0%,79.6%,.14901960784313725);
    border-bottom: 1px solid hsla(0,0%,79.6%,.14901960784313725);
    border-left: none;
}

#header-search-help {
    border-left: 1px solid #5B70AA;
    border-top: 1px solid #5B70AA;
    border-bottom: 1px solid #5B70AA;
    border-right: none;
    color: #fff;
    font-size: 12px;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    font-weight: 300;
    font-family: Montserrat;
    margin-right: 1px;
    background: rgb(31,43,78);
    background: linear-gradient(0deg, rgba(31,43,78,1) 0%, rgba(29,45,86,1) 100%);
}


.search-container-help-centre {
    width: 100%;
}

#help-centre-group-section .search-box-vt .input-group-text {
    background: rgb(31, 43, 78);
    background: linear-gradient(0deg, rgba(31, 43, 78, 1) 0%, rgba(29, 45, 86, 1) 100%);
    border-right: 1px solid #5B70AA;
    border-top: 1px solid #5B70AA;
    border-bottom: 1px solid #5B70AA;
    border-left: none;
}

#help-centre-group-section .settings-item-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #898F97;
}

.cursor-global {
    cursor: pointer;
}

.text-right-arrow {
    color: white;
    margin: 3px 20px;
    font-family: "averta-thin";
    font-size: 12px;
}

.desktop-vip-filter-container {
    display: flex;
    align-items: center;
}

.vip-level-name {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px 0px #0000004D;
    width: 100%;
    min-height: 50px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 20px 10px;
    border-radius: 30px;
    cursor: pointer;
}

    .vip-level-name.active {
        background: linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%), linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1));
        color: #3369FF;
    }



.promotion-wrapper .promotion-row {
    display: flex;
    margin: 30px 0;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
}

    .promotion-wrapper .promotion-row .promo-img {
        width: 50%;
        /*        cursor: pointer;*/
    }

        .promotion-wrapper .promotion-row .promo-img img {
            width: 100%;
        }

    .promotion-wrapper .promotion-row .promo-content {
        width: 50%;
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

        .promotion-wrapper .promotion-row .promo-content .promo-title {
            color: white;
            font-size: 20px;
            font-weight: bold;
        }

        .promotion-wrapper .promotion-row .promo-content .promo-text {
            color: #cbcbcb;
            font-size: 16px;
            margin-top: 5px;
            text-align: justify;
        }

        .promotion-wrapper .promotion-row .promo-content .promo-btn {
            display: flex;
            align-items: center;
        }

            .promotion-wrapper .promotion-row .promo-content .promo-btn .btn-1 {
                background: #3369FF;
                border-radius: 12px;
                color: #fff;
                font-size: 15px;
                width: 40%;
                padding: 10px 15px;
                border: unset;
            }

            .promotion-wrapper .promotion-row .promo-content .promo-btn .btn-2 {
                background: #FF334B;
                border-radius: 12px;
                color: #fff;
                font-size: 15px;
                width: 40%;
                padding: 10px 15px;
                border: unset;
                box-shadow: unset !important;
            }

            .promotion-wrapper .promotion-row .promo-content .promo-btn button:focus-visible {
                outline: none;
            }

            .promotion-wrapper .promotion-row .promo-content .promo-btn .btn-2 {
                margin-left: 10px;
            }

.promo-detail-modal {
    background: #19232F;
}

.custom-cancel-btn {
    background: #FF334B;
    color: white !important;
}

    .custom-cancel-btn:hover {
        background: #A52332;
    }

.promo-detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(242, 1, 29, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(180deg, rgba(78, 75, 200, 0.2) 0%, rgba(0, 84, 182, 0) 100%);
    color: white;
}

.promo-detail-modal .modal-body {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
}

.detail-close-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 40px;
    cursor: pointer;
}

.detail-close-btn {
    font-size: 40px;
    text-align: center;
}

#promotion-detail .detail-img img {
    width: 100%;
}

.promo-detail-header .detail-title {
    font-size: 20px;
    font-weight: bold;
}

#promotion-detail .detail-title {
    color: white;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.7px;
    margin-top: 10px;
}

#promotion-detail .detail-content {
    color: #cbcbcb;
    font-size: 16px;
    margin-top: 10px;
}

#promotion-detail figure.image img {
    max-width: 100%;
}

#promotion-detail .detail-content table td {
    border: 1px solid #fff;
    padding: 5px;
    color: white;
}

.modal-reload-success.modal-content {
    background: rgba(16, 33, 86, 1);
    border-radius: 8px;
}

.modal-reload-success .table {
    color: white;
}

.saved-bank-outer-container > label {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

    .saved-bank-outer-container > label > span {
        cursor: pointer;
        color: #3369FF;
        text-decoration: underline;
    }

.saved-bank-item-wrapper {
    font-size: 11px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px 0px #0000004D;
    border: 0px !important;
    color: #898f97;
}

.withdrawal-saved-bank button {
    display: grid;
    grid-template-columns: 0.5fr 8.5fr 1fr;
    border: unset;
    color: white;
    background: transparent;
    width: 100%;
    align-items: center;
}

.withdrawal-saved-bank {
    font-size: 12px;
    margin: unset;
    padding: unset;
}

    .withdrawal-saved-bank.active button {
        color: #3369FF;
    }

    .withdrawal-saved-bank button > div {
        display: flex;
    }

.withdrawal-saved-bank-description {
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 5px;
}

.withdrawal-saved-bank-title,
.withdrawal-saved-bank-accountno {
    font-weight: 500;
}

.withdraw-save-bank-icon2 {
    justify-content: end;
}

    .withdraw-save-bank-icon2 .remove-text {
        cursor: pointer;
        color: red;
        font-weight: 700;
    }

.saved-bank-lines {
    background: rgba(255, 255, 255, 0.3);
    width: 97%;
}

.saved-bank-details-wrapper {
    display: grid;
    grid-template-columns: 0.5fr 9.5fr;
}

.saved-bank-detail-item > span {
    font-size: 14px;
    font-weight: 700;
}

.saved-bank-detail-item > div > span {
    opacity: 0.4;
    font-weight: 400;
}

.right-custom-way-item {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

    .right-custom-way-item:last-child {
        margin-bottom: 0px;
    }

    .right-custom-way-item .first {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

        .right-custom-way-item .first .title {
            font-size: 12px;
        }

        .right-custom-way-item .first .provider {
            font-size: 9px;
            color: #898F97;
        }

.sub-title {
    padding-left: 10px;
}

    .sub-title:after {
        content: ' ';
        background-color: #3369FF;
        display: block;
        width: 100px;
        height: 2px;
        border-radius: 100px;
        margin-top: 3px;
        margin-bottom: 10px;
    }

.sub-sub-title {
    padding-left: 10px;
}

.helpcentre-active {
    color: #3369FF;
}

#withdrawal-box .bank-dropdown-wrapper > div > div:nth-child(3) {
    background: rgba(16, 33, 86, 1);
    border-radius: 8px;
}

#withdrawal-box .bank-dropdown-wrapper > div > div > div > div {
    color: white;
}

    #withdrawal-box .bank-dropdown-wrapper > div > div > div > div:hover {
        background-color: #2684FF;
    }

.del-confirmation-modal {
    background: rgba(16, 33, 86, 1);
}

.del-success-modal {
    background: rgba(16, 33, 86, 1);
}

.del-confirmation-modal .del-confirmation-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    text-align: center;
}

.del-success-modal .del-success-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    text-align: center;
}

.del-confirmation-body .btn-container {
    display: flex;
    gap: 15px;
}

.login-tac-modal .custom-pin-container input {
    width: 65px !important;
}

.tnc-popup-wrapper {
    height: 100vh;
    width: 100vw;
    background: black;
    opacity: 0.5;
    position: fixed;
    left: 0;
    top: 0;
}

.tnc-popup-v3 .tnc-popup-header {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #3369FF;
    margin-bottom: 15px;
}

.tnc-popup-v3 {
    background: linear-gradient(180deg, rgba(36, 80, 202, 0.2) 0%, rgba(248, 230, 255, 0) 100%);
    /*  background: #0e1a3c;
  height: 400px;
  border-radius: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);*/
    backdrop-filter: blur(70px);
    position: fixed;
    /*  align-items: center;*/
    width: 75vw;
    height: 75vh;
    /*  background-color: white;*/
    padding: 20px 40px;
    left: 12vw;
    top: 15vh;
    border-radius: 20px;
    z-index: 2;
    display: grid;
}

    .tnc-popup-v3 .tnc-container {
        overflow-y: scroll;
        overflow-x: clip;
        height: 50vh;
        margin-bottom: 10px;
        text-align: justify;
        font-size: 16px;
        /* font-size: 12px; */
        color: #002C68;
        letter-spacing: 0.2px;
        padding: 0;
        padding-right: 20px;
        position: relative;
        font-weight: normal;
    }

        .tnc-popup-v3 .tnc-container::-webkit-scrollbar {
            background-color: #000D1E;
            width: 5px;
        }

        .tnc-popup-v3 .tnc-container::-webkit-scrollbar-thumb {
            background-color: #004FBA;
        }

        .tnc-popup-v3 .tnc-container .tnc-content {
            margin-top: 0;
        }

            .tnc-popup-v3 .tnc-container .tnc-content hr {
                border: unset !important;
                background: #2D3C6B !important;
            }

            .tnc-popup-v3 .tnc-container .tnc-content div {
                color: #fff;
            }

        .tnc-popup-v3 .tnc-container .tnc-top-text {
            color: #3369FF !important;
            margin-top: 20px;
            margin-bottom: 0.5rem;
            border-bottom: none;
            font-size: 15px !important;
        }

            .tnc-popup-v3 .tnc-container .tnc-top-text span {
                color: #3369FF !important;
            }

        .tnc-popup-v3 .tnc-container .tnc-label {
            color: #3369FF;
            margin-top: 20px;
            border-bottom: none;
        }

    .tnc-popup-v3 button {
        background: #3369FF !important;
        border-radius: 15px;
        color: #fff !important;
        font-size: 15px;
        min-width: 150px;
        padding: 10px 15px;
        border: unset;
    }

.sign-in {
    cursor: pointer;
}

/* custom header dropdown css start*/

.custom-dropdown {
    position: relative;
    width: 210px;
}

    .custom-dropdown:hover .options {
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
    }

.selected-option {
    border: 1px solid #ccc;
    /*  padding: 8px;*/
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.arrow {
    /*  margin-right: 20px;*/
}

    .arrow.open {
        /*  transform: rotate(180deg);*/
    }

.options {
    width: 100%;
    border-radius: 0 0 5px 5px;
    z-index: 500;
    padding-top: 8px;
    display: none;
}

.option {
    cursor: pointer;
}

    .option:hover {
        background: rgba(40, 66, 144, 1);
    }

.custom-dropdown .options .header-wallet-container {
    border-radius: unset;
}

    .custom-dropdown .options .header-wallet-container:first-child {
        border-radius: 8px 8px 0 0;
        border-bottom: unset;
        border-bottom-width: 0;
        position: relative;
    }

        /* Add border with gaps */
        .custom-dropdown .options .header-wallet-container:first-child:after {
            position: absolute;
            content: '';
            border-bottom: 1.5px solid #3369FF;
            width: 90%;
            transform: translateX(-50%);
            bottom: -1px;
            left: 50%;
        }

    .custom-dropdown .options .header-wallet-container:last-child {
        border-radius: 0 0 8px 8px;
        border-top: unset;
        border-top-width: 0;
        position: relative;
    }

        /* Add border with gaps */
        .custom-dropdown .options .header-wallet-container:last-child:before {
            position: absolute;
            content: '';
            border-top: 1.5px solid #3369FF;
            width: 90%;
            transform: translateX(-50%);
            top: -1px;
            left: 50%;
        }

.custom-dropdown .header-wallet-container .header-balance {
    width: 100%;
}

.header-wallet-container .header-balance .second {
    border-right: 2px solid #3369ff;
}

    .header-wallet-container .header-balance .second > span {
        color: transparent;
    }

.header-wallet-container .header-balance .third {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: unset;
}

    .header-wallet-container .header-balance .third > span:first-child {
        font-weight: 300;
    }

.header-wallet-container .header-balance .arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    margin-left: auto;
}

/* custom header dropdown css end*/
/* custom header dropdown 2 css start*/

.custom-dropdown-2 {
    position: relative;
    width: 150px;
    display: flex;
    justify-content: space-between;
    background: #3369FF;
    height: 35px;
    border-radius: 8px;
    padding: 5px 8px;
}

    .custom-dropdown-2:hover .options {
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
    }

    .custom-dropdown-2 .dropdown-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .custom-dropdown-2 .dropdown-item-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .custom-dropdown-2 .dropdown-container .selected-option {
        border: 1px solid #ccc;
        padding: 8px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
    }

    .custom-dropdown-2 .dropdown-container .arrow {
        margin-left: 8px;
    }

        .custom-dropdown-2 .dropdown-container .arrow.open {
            transform: rotate(180deg);
        }

    .custom-dropdown-2 .options {
        display: none;
        width: 100%;
        border-radius: 8px;
        padding-top: 8px;
    }

        .custom-dropdown-2 .options .dropdown-container {
            height: 40px;
            padding: 5px 8px;
            background: rgba(23, 41, 91, 1);
        }

            .custom-dropdown-2 .options .dropdown-container:first-child {
                border-radius: 8px 8px 0 0;
            }

            .custom-dropdown-2 .options .dropdown-container:last-child {
                border-radius: 0 0 8px 8px;
            }

    .custom-dropdown-2 .dropdown-container .option {
        padding: 8px;
        cursor: pointer;
    }

    .custom-dropdown-2 .option:hover {
        opacity: unset;
        background: unset;
    }

    .custom-dropdown-2 .options .dropdown-container.option:hover {
        background: rgba(40, 66, 144, 1);
    }

/* custom header dropdown 2 css end */

/* success modal css start */
.custom-success-modal {
    /*  background: linear-gradient(180deg, rgba(36, 80, 202, 0.2) 0%, rgba(248, 230, 255, 0) 100%);*/
    background: #0e1a3c;
    height: 400px;
    border-radius: 30px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    /*  backdrop-filter: blur(70px)*/
}

.modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    height: 100%;
}

    .modal-container .img-wrapper img {
        width: 120px;
        height: 120px;
    }

    .modal-container .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

        .modal-container .text-container .title-wrapper span {
            font-size: 24px;
        }

        .modal-container .text-container .description-wrapper {
            margin-top: 15px;
            text-align: center;
        }

            .modal-container .text-container .description-wrapper span {
                font-weight: 300;
            }

    .modal-container .btn-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 20px
    }

        .modal-container .btn-wrapper .primary-btn {
            background: #3369FF;
            border-radius: 15px;
            color: #fff;
            font-size: 15px;
            min-width: 150px;
            padding: 10px 15px;
            border: unset;
        }

        .modal-container .btn-wrapper .secondary-btn {
            background: #FF334B;
            border-radius: 15px;
            color: #fff;
            font-size: 15px;
            min-width: 150px;
            padding: 10px 15px;
            border: unset;
        }

/* success modal css end */

.withdrawal-name-alert {
    background-color: #11214D !important;
    border: 1px solid #2C5BDB !important;
    color: #fff !important;
    border-radius: 8px;
}

.withdrawal-warning-text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
}

    .withdrawal-warning-text .img-wrapper img {
        width: 20px;
        height: 20px;
    }

.promotion-detail-wrapper {
    padding: 15px;
}

    .promotion-detail-wrapper .detail-content {
        text-align: justify;
    }

    .promotion-detail-wrapper .detail-button {
        display: flex;
        align-items: center;
        justify-content: center;
    }

        .promotion-detail-wrapper .detail-button button {
            background: #3369FF;
            border-radius: 8px;
            color: #fff;
            font-size: 15px;
            width: 50%;
            padding: 10px 15px;
            border: unset;
        }

.html5-video-player .video-stream {
    height: 100% !important;
    top: unset !important;
}

#referral-edit-section .group-name-label {
    font-size: 16px;
}

#referral-edit-section .custom-input-style-1 {
    font-size: 14px;
}

#referral-edit-section .custom-btn-style-1 {
    margin-top: 25px
}

.custom-h4.contact-us-tips-title {
    color: #fff;
}

/* custom header dropdown 2 css end */



/*game section v3 start */

.game-section-v3-category-box {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
}

    .game-section-v3-category-box li {
        padding: 20px 0px;
        background: rgba(16, 33, 86, 1);
        flex: 1;
        text-align: center;
        cursor: pointer;
    }

        .game-section-v3-category-box li:first-child {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }

        .game-section-v3-category-box li:last-child {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        }

        .game-section-v3-category-box li:hover {
            background: linear-gradient( 90deg, rgba(66, 90, 164, 1) 56%, rgba(36, 62, 141, 1) 100% );
            color: rgba(255, 217, 40, 1);
        }

            .game-section-v3-category-box li:hover .cat-item .cat-img {
                filter: brightness(0) saturate(100%) invert(84%) sepia(77%) saturate(1468%) hue-rotate(329deg) brightness(99%) contrast(104%);
            }

        .game-section-v3-category-box li.active {
            background: linear-gradient( 90deg, rgba(66, 90, 164, 1) 56%, rgba(36, 62, 141, 1) 100% );
            color: rgba(255, 217, 40, 1);
        }

            .game-section-v3-category-box li.active .cat-item .cat-img {
                filter: brightness(0) saturate(100%) invert(84%) sepia(77%) saturate(1468%) hue-rotate(329deg) brightness(99%) contrast(104%);
            }

        .game-section-v3-category-box li .cat-item {
            display: flex;
            align-items: center;
            justify-content: center;
        }

            .game-section-v3-category-box li .cat-item .cat-img {
                margin-right: 10px;
            }

.game-section-v3-provider-container {
    display: grid;
    margin-top: 30px;
    padding-bottom: 10px;
    /*  flex-wrap: wrap;
    justify-content: flex-start;
    align-content: space-between;*/
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: repeat(5, 1fr);
}

@media screen and (min-width: 1920px) {
    .game-section-v3-provider-container {
        grid-template-columns: repeat(6, 1fr);
    }
}

.game-section-v3-provider-container::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    background-color: transparent;
    border-radius: 100px;
}

.game-section-v3-provider-container::-webkit-scrollbar {
    height: 5px !important;
    background-color: #d9d9d914;
    border-radius: 100px;
}

.game-section-v3-provider-container::-webkit-scrollbar-thumb {
    background-color: #D9D9D980;
    border-radius: 100px;
    cursor: pointer;
}

.game-section-v3-provider-container .provider-items {
    width: auto;
    position: relative;
}

@media screen and (max-width : 1020px) {
    .game-section-v3-provider-container .provider-items {
        width: 192px;
    }
}

.game-section-v3-provider-container .provider-items .v2-um-tag-icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.game-section-v3-slots-provider-box {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    width: 100%;
    padding-bottom: 5px;
    background: transparent;
    scroll-behavior: smooth;
    margin:unset;
}

    .game-section-v3-slots-provider-box.dragging {
        scroll-behavior: auto;
    }

    .game-section-v3-slots-provider-box::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: transparent;
    }

    .game-section-v3-slots-provider-box::-webkit-scrollbar {
        height: 5px;
        background-color: #d9d9d914;
    }

    .game-section-v3-slots-provider-box::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ffc159;
        cursor: pointer;
    }

    .game-section-v3-slots-provider-box ul{
        padding-bottom: unset;
    }

    .game-section-v3-slots-provider-box li {
        padding: 10px 0px;
        background: rgba(16, 33, 86, 1);
        flex: 0 0 calc(100% / 7);
        text-align: center;
        position: relative;
        user-select: none;
    }

        .game-section-v3-slots-provider-box li img {
            height: 50px;
            filter: grayscale(100%);
        }

@media screen and (min-width: 1920px) {
    .game-section-v3-slots-provider-box li img {
        height: 60px;
    }
}

.game-section-v3-slots-provider-box li:hover img, .game-section-v3-slots-provider-box li.active img {
    filter: grayscale(0%);
}

.product-games-list-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 0 10px;
    align-items: start;
}

    .product-games-list-container .product-games-list-items {
        text-align: center;
        cursor: pointer;
    }

        .product-games-list-container .product-games-list-items .product-game-box {
            position: relative;
            /* width: 100%; */
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: 15px;
        }

            .product-games-list-container .product-games-list-items .product-game-box .tag {
                position: absolute;
                top: -10px;
                left: -5px;
                /* z-index: 998; */
                z-index: 1;
            }


            .product-games-list-container .product-games-list-items .product-game-box .product-game-image img {
                width: 100%;
                height: 100%;
                object-fit: cover; /* Ensures the image covers the entire container */
                object-position: center; /* Centers the image within the container */
                aspect-ratio: 1 / 1;
            }

            .product-games-list-container .product-games-list-items .product-game-box .product-game-name {
                color: white;
                font-size: 14px;
                word-break: break-word;
                overflow-wrap: break-word;
                margin: 10px 0px;
                text-align: center;
            }

.game-section-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
}

.more-games-button {
    color: white;
    text-align: center;
    background-color: dodgerblue;
    border-radius: 15px;
    width: 250px;
    padding: 10px;
    border: 0px;
    text-align: center;
}

#confirm-non-seamless-transfer-modal .modal-body {
    background: white;
    color: black;
    padding: 30px;
    border-radius: 10px;
}

    #confirm-non-seamless-transfer-modal .modal-body .question {
        font-size: 24px;
        text-align: center;
    }

    #confirm-non-seamless-transfer-modal .modal-body .buttons {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

.pandding-top-global {
    padding-top: 5px;
}

.deposit-tutorial {
    margin-top: 15px;
}

.modal-X-btn-1 {
    border: unset;
    background: unset;
    color: #fff;
}

    .modal-X-btn-1 span {
        font-size: 25px;
    }

.tutorial-content-wrapper {
    margin-top: 35px;
}


#modal-small-custom .ref-share {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin: 50px 0 70px;
}

.ref-share > div.st-inline-share-buttons {
    display: flex !important;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.st-btn {
    z-index: 100;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: unset !important;
    width: 40px !important;
}

    .st-btn::before {
        content: '';
        position: absolute;
        /*  background: rgba(255, 0, 0, 1);*/
        background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
        width: 60px;
        height: 60px;
        left: -10px;
        top: -10px;
        z-index: 0;
        border-radius: 50%;
    }

    .st-btn:nth-child(1):after {
        content: 'WhatsApp';
        position: absolute;
        z-index: 0;
        top: 120%;
        font-family: Montserrat;
        color: #A4A4A4;
    }

    .st-btn:nth-child(2):after {
        content: 'Facebook';
        position: absolute;
        z-index: 0;
        top: 120%;
        font-family: Montserrat;
        color: #A4A4A4;
    }

    .st-btn:nth-child(3):after {
        content: 'Twitter';
        position: absolute;
        z-index: 0;
        top: 120%;
        font-family: Montserrat;
        color: #A4A4A4;
    }

    .st-btn:nth-child(4):after {
        content: 'WeChat';
        position: absolute;
        z-index: 0;
        top: 120%;
        font-family: Montserrat;
        color: #A4A4A4;
    }

    .st-btn:nth-child(5):after {
        content: 'Telegram';
        position: absolute;
        z-index: 0;
        top: 120%;
        font-family: Montserrat;
        color: #A4A4A4;
    }

    .st-btn > img {
        top: 0 !important;
        width: 25px !important;
        height: 25px !important;
    }


.reset-pin-ref a {
    color: #898f97;
    font-size: 10px
}

.pincode-input-container {
    display: flex;
    grid-column-gap: 10px;
}

    .pincode-input-container input {
        font-size: 24px;
        font-family: "poppins-medium";
        border: none !important;
        border-bottom: 2px solid #FFF !important;
        color: #A4A4A4;
        outline: unset;
        text-align: center;
        width: 100% !important;
    }

.send-verification-code {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(164, 164, 164, 1);
    margin-top: 20px;
}

.transaction-pin .title {
    font-size: 20px;
}

.transfer-p-note {
    color: #898F97;
    font-size: 12px;
    font-style: italic;
    margin-left: 5px;
}

.transfer-pin-modal .pincode-input-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    width: 50%;
    margin: 0 auto;
}

    .transfer-pin-modal .pincode-input-container input {
        margin: unset !important;
        border-bottom: 2px solid #FFF !important;
    }

.help-centre-div {
    margin: 20px 0px 0px;
}

.custom-style-selection > div > div:nth-child(2):before {
    background-image: radial-gradient(61.69% 212.78% at 108.28% 168.58%, #3369FF 40.77%, rgba(255,255,255,0) 100%), radial-gradient(79.38% 146.5% at -21.75% -8.36%, #3369FF 40.77%, rgba(255,255,255,0) 100%);
    -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
    -webkit-mask-composite: xor;
    border-radius: 8px;
    padding: 1px;
    cursor: pointer;
    content: '';
    inset: 0;
    position: absolute;
}


/*.custom-select-box > div > div:last-child {
        background: #15295f;
        border-radius: 8px;
        opacity: 1;
    }
    .custom-select-box > div > div > div  {
        color: #fff;
    }

    .custom-select-box > div > div:nth-child(3) > div {
       background:  radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 100%) !important;
    }

        .custom-select-box > div > div:nth-child(3) > div > div:hover {
            background: #3369FF;
        }

        .custom-select-box > div > div:nth-child(3) > div > div::selection{
            background:red;
        }*/

.referral-code-text {
    color: white;
    font-weight: bold;
    display: flex;
    margin-top: 15px;
}

    .referral-code-text span {
        color: red;
    }

.slots-left-right-btn {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
    font-size: 25px;
}

.left-btn, .right-btn {
    width: 40px;
    border: 1px solid white;
    border-radius: 25px;
    margin: 0 8px;
    cursor: pointer;
}

.game-section-v3-slots-provider-box li .v2-um-tag-icon {
    /*top: 0;
    left: 50px;*/
}

.slots-left-right-btn-filter {
    display: flex;
    justify-content: flex-end;
    margin: 10px;
    font-size: 25px;
    height: 30px;
}

.left-btn-filter, .right-btn-filter {
    width: 30px;
    border: 1px solid white;
    border-radius: 25px;
    margin: 0 8px;
    cursor: pointer;
}

.arrow-filter {
    font-size: 20px;
    text-align: center;
}

.no-data-found-text {
  padding: 0 0 0 20px;
  margin-left: unset !important;
}

.promotion-img {
    border-radius: 8px;
}

.slot-provider-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.scroll-left-btn, .scroll-right-btn {
    z-index: 1;
    background: #304998;
    top: 0;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom:5px;
}

.scroll-left-btn {
    left: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.scroll-right-btn {
    right: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.claim-btn:disabled {
    background: grey;
    cursor: not-allowed;
}

.referral-edit-details-width {
    width: 70%;
}

.referral-edit-details-comm {
    width: 80px;
}

.comm-accordion-item {
    margin-bottom: 15px;
}

.comm-accordion-header {
    width: 100%;
    text-align: justify;
    font-family: inherit;
}
    .comm-accordion-header > button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        background: linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%), linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1));
        border: none;
        border-radius: 10px;
        color: white;
        height: 75px;
    }
    .comm-accordion-header .left .title{
        font-size: 15px;
    }
    .comm-accordion-header .left .date {
        font-size: 13px;
    }
    .comm-accordion-header .accordion-button .left {
        text-align: left;
    }

#commission-section .accordion-body {
    background: linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%), linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1));
    border-radius: 10px;
}

.comm-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    font-size: 13px;
}

a.comm-rate-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    margin-bottom: 10px;
    background: linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%), linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1));
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    color: white;
    border-radius: 10px;
    cursor: pointer;
}

.category-slider {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
}
    .category-slider .category {
        min-width: 100px;
        text-align: center;
        padding: 5px 10px;
        margin: 5px 10px 5px 0px;
        font-size: 15px;
        background: linear-gradient(271.54deg, rgba(51, 105, 255, .12) -18.33%, rgba(27, 42, 84, .3) 100%, rgba(27, 42, 84, .3) 100%), linear-gradient(0deg, rgba(51, 105, 255, .1), rgba(51, 105, 255, .1));
        box-shadow: 0 4px 7px #0000004d;
        border-radius: 25px;
        cursor: pointer;
    }
        .category-slider .category.active {
            color: #3369ff;
        }

.product-row {
    display: flex;
    flex-direction: column;
    width: 100%;
}
    .product-row.row-0 {
        margin-top: 15px;
    }
    .product-row .top {
        width: 100%;
        display: flex;
        font-size: 15px;
    }
    .product-row .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;
        flex-direction: column;
    }
        .product-row .bottom .left, .product-row .bottom .right {
            display: flex;
            width: 50%;
            font-size: 13px;
        }
        .product-row .bottom .right {
            justify-content: flex-end;
        }

.title-4dresult-country-selection {
    display: flex;
    align-items: center;
}

.country-list {
    display: flex;
    column-gap: 10px;
    margin-left: 10px;
}

    .country-list .country-icon {
        height: 25px;
    }

    .country-list .country-name {
        margin-left: 5px;
        font-size: 13px;
    }

.country-btn-wrapper {
    background: #e7e7e7;
    color: black;
    border-radius: 30px;
    padding: 5px 10px 5px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

    .country-btn-wrapper.active {
        color: white;
        background: #3369FF;
    }

.cat-title-text {
    margin-left: 5px;
    color: #3369ff;
}

.product-rate-detail-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.comm-accordion-header > button:focus-visible {
    outline: none;
}

.kpi-table > .table thead tr th {
    color: black;
    font-size: 12px;
    border-bottom: 2px solid #5B70AA !important;
}

.kpi-table > .table td {
    border-bottom: 2px solid #212C49 !important;
}
.kpi-table .reportTable td, .reportTable th {
    color: black;
}

.kpi-table > .table tfoot tr td {
    background: #dbe6ff;
    color: black;
}

.um-display {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.hr-right-new {
    background: hsla(0, 0%, 100%, 0.24);
    width: 60%;
    max-width: 500px;
    height: 1px;
    margin: 20px 0px;
    margin-left: auto;
    margin-right: 0px;
}

.promotion-new {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: 20px;
}

#wallet-statement .reportTable td, .reportTable th {
    color: white;
}

#bonus-statement .reportTable td, .reportTable th {
    color: white;
}

#game-record .reportTable td, .reportTable th {
    color: white;
}

#transaction-history .reportTable td, .reportTable th {
    color: white;
}
#loyalty-redemption-history .reportTable td, .reportTable th {
    color: white;
}

.table tfoot tr td {
    font-weight: bold;
}


#vip-pin-modal-new .custom-btn-style-1 {
    margin-top: 25px
}

#vip-section .shareholder-my-income .content-title {
    margin-bottom: 0px;
}

#vip-section .my-income-card-content .content-value {
    font-size: 16px;
    color: #3369FF;
    margin-top: 6px;
}

#shareholder-section .shareholder-data {
    color: #000;
}

#shareholder-section .vip-event-downline-data {
    margin-top: -15px;
}

.vip-event-table {
    background: white; /*linear-gradient(260deg, rgba(20, 36, 77, 1) 0%, rgba(19, 33, 66, 1) 100%);*/
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    /*  padding: 15px 20px 0px;*/
    overflow: hidden;
}

#shareholder-section .custom-btn {
    box-shadow: none;
}


.referral-add-minus-new {
    margin-left: 0px;
    display: flex;
    grid-column-gap: 20px;
    column-gap: 20px;
    align-items: center;
    background: linear-gradient(0deg,rgba(51,105,255,.1),rgba(51,105,255,.1)),linear-gradient(271.54deg,rgba(51,105,255,.12) -18.33%,rgba(27,42,84,.3));
    box-shadow: 0 4px 7px rgba(0,0,0,.3);
    border-radius: 30px;
    padding: 0 20px;
    height: 45px;
    border: 0px;
    appearance: none;
    color: white;
    width: 120px;
    text-align: -webkit-center;
}

.text-right-arrow-new {
    color: #3369ff;
    margin: 3px 20px;
    font-family: "averta-thin";
    font-size: 12px;
}

#loyal-history-box.template-box {
  background: linear-gradient(260deg, rgba(20, 36, 77, 1) 0%, rgba(19, 33, 66, 1) 100%);
}

#loyal-history-box.template-box .reportTable td,
#loyal-history-box.template-box .reportTable th {
  color: #fff;
}

#loyal-history-box.template-box table tfoot:after {
  width: 100%;
  left: unset;
}

.see-more-text {
    margin: 15px 0;
    color: #0078ff;
    text-decoration: underline;
    cursor: pointer;
}

.rewards-history-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rewards-history-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
}
    .rewards-history-btn > svg {
        margin-left: 5px;
    }

.claim-history-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.claim-history-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
}
    .claim-history-btn > svg {
        margin-left: 5px;
    }

.comm-history-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comm-history-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
}

    .comm-history-btn > svg {
        margin-left: 5px;
    }


.whatsapp-or-div {
    display: flex;
    flex-direction: row;
    color: rgba(137, 143, 151, 1);
}

    .whatsapp-or-div:before, .whatsapp-or-div:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid;
        margin: auto;
    }

    .whatsapp-or-div:before {
        margin-right: 10px
    }

    .whatsapp-or-div:after {
        margin-left: 10px
    }

.whatsapp-btn-div {
    border: 1.5px solid rgba(51, 105, 255, 1);
    border-radius: 15px;
    padding: 3px;
    cursor: pointer;
}

.whatsapp-btn {
    background: linear-gradient(271.54deg, rgba(51, 105, 255, 0.4) -18.33%, #1B2A54 100%),linear-gradient(0deg, rgba(25, 35, 47, 0.5), rgba(25, 35, 47, 0.5));
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 14px;
    font-family: Montserrat;
    grid-column-gap: 20px;
}

.modal-content {
    background-color: rgba(16,33,86,1) !important;
}

#banner-popup-modal {
    margin: 0 auto;
}

    #banner-popup-modal .banner-popup-modal-body {
        width: auto;
        height: 100vh;
        margin: auto;
        background-color: transparent !important;
        border: unset;
        border-radius: 5px;
        background: green;
    }


        #banner-popup-modal .banner-popup-modal-body img {
            max-height: calc(100vh - 6rem);
            object-fit: contain;
            object-position: center;
        }

#inactive-downline.inactive-downline-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align:center;
}

#inactive-downline .left {
    width: calc(100% - 330px);
}

#inactive-downline .right {
    width: 300px;
}

#inactive-downline {
    display: flex;
    grid-column-gap: 30px;
    column-gap: 30px;
}