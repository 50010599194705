#downline-section .template-box {
    border-radius: 10px;
}

.downline-main-boxes {
    background-color: #02214A;
    padding: 13.5px 23px 12.5px 14px;
    border-radius: 10px;
    margin-top: 18px;
    letter-spacing: 0px;
    line-height: 17px;
}

.downline-accordion-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.downline-accordion-wrapper {
    border-top: 1px solid #000000;
    padding: 16px 0 0;
}

.downline-accordion-details {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    margin-bottom: 8px;
}

.downline-accordion-title .img2 {
    display: none;
}

.downline-accordion-title[aria-expanded="true"] .img1 {
    display: none;
}

.downline-accordion-title[aria-expanded="true"] .img2 {
    display: block;
}

.d-item {
    width: 100%;
    display: block;
    text-align: right;
    max-width: 150px;
}

.d-level {
    width: 100%;
    display: block;
    text-align: center;
    max-width: 150px;
    background: linear-gradient(180deg, rgb(252 235 55) 0%, rgba(253, 182, 46, 1) 56%, rgba(254, 126, 37, 1) 100%);
    border-radius: 30px;
    letter-spacing: 0px;
    color: #002E6C;
    padding: 8px 0;
}

.d-edit-details {
    text-decoration-line: underline !important;
}

#downlineform .username {
    margin: 0 0 24px;
}

#downlineform .nickname {
    margin: 0 0 12px;
}

#downlineform .d-form {
    gap: 44px;
    margin: 0 0 22px;
}

#downlineform .form-white-input {
    padding: 16px 24px 15px;
    font-size: 11px;
    border-radius: 30px;
    background: #02214A;
    border: 1px solid #fff;
    color: #fff;
    width: 100%;
    height: 45px;
}

#downlineform .submit {
    background: transparent linear-gradient(180deg, #FCEB37 0%, #FE7E25 100%) 0% 0% no-repeat padding-box;
    border-radius: 30px;
    color: #001A3D;
    width: 100%;
    height: 45px;
    border: 0;
}

#downlineform .d-group {
    column-gap: 15px;
}

#downlinetableform .submit,
#downline2 .submit,
#downline3 .submit,
#contactus .submit {
    background: transparent linear-gradient(180deg, #FCEB37 0%, #FE7E25 100%) 0% 0% no-repeat padding-box;
    border-radius: 30px;
    color: #001A3D;
    width: 100%;
    height: 40px;
    border: 0;
}

    #downlinetableform .submit:disabled {
        opacity: 0.4;
        color: grey;
        cursor: not-allowed;
    }

#downlinetableform .row-data-max {
    background: #FFC159 0% 0% no-repeat padding-box;
    border-radius: 20px;
    color: #002E6C;
    font-size: 14px;
    padding: 12px 15px;
    text-align: center;
    cursor: pointer;
    width: 30%;
}

#downlinetableform .row-data-3 .plus-minus-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #FFFFFF;
    border-radius: 20px;
    padding: 5px 0;
}

#downlinetableform .row-data-3 .edit-plus-minus-icon {
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    opacity: 1;
}

#downlinetableform .row-data-3 .bonusComm-option-select {
    appearance: none;
    text-align: center;
    background: transparent;
    border: none;
    color: #FFC159;
}

#downlinetableform .row-data-4 div {
    width: 62px;
}

#edit-downline-group,
#edit-downline {
    overflow: auto;
}

#downlinetableform .edit-downline-table .data-row.category:not(:nth-child(1)) {
    margin-top: 0;
}

#downlinetableform .edit-downline-table .data-row {
    padding: 5px 0;
}

    #downlinetableform .edit-downline-table .data-row .row-data-1 {
        min-width: 150px;
    }

    #downlinetableform .edit-downline-table .data-row .row-data-2 {
        min-width: 80px;
    }

.downline-edit-container .border-bot-black {
    background: transparent;
    border-bottom: 1px solid #000;
    padding-bottom: 20px;
    margin: 0 0 20px;
}

#downlinetableform .edit-downline-submitbtn {
    padding: 16px 0 0;
}

#commrate {
    margin-bottom: 0;
}

    #commrate th {
        width: 80%;
    }

    #commrate th,
    #commrate td {
        font-family: 'averta-semibold';
        font-size: 14px;
        color: #FFFFFF;
    }

#downline2 .content,
#downline3 .content {
    margin: 17px 0 0;
}

.d-title {
    margin: 18px 0 15px;
}

#downline3 .downline-games {
    background-color: #02214A;
    padding: 3px 48px 32px;
    border-radius: 10px;
    margin-top: 18px;
    letter-spacing: 0px;
    line-height: 17px;
}

#downline3 .downline-accordion-wrapper {
    border: none;
}

#downline3 .downline-main-boxes {
    background: none;
    border-bottom: 1px solid #000000;
    border-radius: 0;
    padding: 30px 0;
    margin: 0;
}

@media (max-width: 401px) {
    .row-data-4 div {
        width: 62px !important;
        font-size: 13px;
    }

    .d-form .font-16 {
        font-size: 12px;
    }
}

#result4d .template-box {
    border-radius: 10px;
    margin: 18px 0 30px;
    padding: 0;
}

#result4d .owl-carousel.owl-drag .owl-item-container {
    border-radius: 10px;
    padding: 10px 0;
    margin: 0 30px 0 0;
}

    #result4d .owl-carousel.owl-drag .owl-item-container .media-object {
        width: 55px;
        height: 55px;
        border-radius: 7px;
        margin: auto auto 7px;
    }

#result4d .card-package.card-result {
    background: #fff 0% 0% no-repeat padding-box;
    border-radius: 14px;
    color: #02214a !important;
}

#result4d .pool-name {
    text-transform: uppercase;
    font-size: 17px;
}

#result4d .table {
    color: #02214a !important;
}

    #result4d .table .text-white {
        color: #02214a !important;
    }

#result4d .card-body table {
    width: 100%;
    text-align: center;
    /* border-collapse: separate; */
    /* border-spacing: 0 20px; */
}

#result4d .banner {
    margin: 0 0 28px;
}

#result4d .table tbody td,
.table tbody th {
    border: none;
}

#result4d .pool-result {
    padding-bottom: 30px;
}

    #result4d .pool-result .pool-calendar {
        position: relative;
        margin: 10px 0 20px;
    }

        #result4d .pool-result .pool-calendar .react-datepicker-wrapper {
            width: 100%;
        }

            #result4d .pool-result .pool-calendar .react-datepicker-wrapper input {
                background: #001A3D 0% 0% no-repeat padding-box;
                border-radius: 10px;
                color: #FFFFFF;
                border: none;
            }

    #result4d .pool-result .card-header {
        border: none;
    }

#result4d .result-wrapper {
    border-radius: 14px;
    padding: 13px 10px 10px 10px;
    margin-bottom: 30px;
}

#result4d .result-card-header {
    padding: 0px 0px 9px 15px;
}

#result4d .white-background {
    background-color: #FFFFFF;
}

#result4d .color-wrapper {
    background-color: #FFC159;
}

#result4d .draw-date-no-section {
    padding: 8px 15px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

#result4d .result-card-body {
    border-radius: 6px;
}

#result4d .form-white-search-input {
    padding: 0px 24px 0px 24px;
    font-size: 15px;
    background: transparent linear-gradient(90deg, #113C76 0%, #022556 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 4px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    border: none;
    height: 40px;
    color: #FFFFFF;
}

    #result4d .form-white-search-input::placeholder {
        color: #FFFFFF;
        opacity: 0.6;
    }

#result4d .result4d-search-icon {
    position: absolute;
    right: 30px;
    top: 8px;
}

#result4d .background-container {
    background-color: #02214A;
    padding: 48px 64px;
    border-radius: 10px;
    /* margin-top: 35px;
    margin-bottom:35px; */
    margin-top: 48px;
    margin-bottom: 48px;
}

#result4d .title-4dresult {
    font-family: "Roboto", sans-serif;
    color: #fff;
    font-size: 22px;
    font-weight: bold;
}

#result4d .prize-type {
    letter-spacing: 0.24px;
}

#result4d .letter-spacing-num {
    letter-spacing: 3.32px;
}

#livechat {
    min-height: 50vh;
}

    #livechat .background-container {
        background-color: #02214A;
        padding: 48px 64px;
        border-radius: 10px;
        margin-top: 48px;
        margin-bottom: 48px;
    }

    #livechat .title-4dresult {
        font-family: "Roboto", sans-serif;
        color: #fff;
        font-size: 22px;
        font-weight: bold;
    }

#contactus .template-box {
    border-radius: 10px;
    margin: 18px 0 30px;
    padding: 0;
    background: none;
}

.channel-content {
    gap: 10px;
    /* flex-wrap: wrap; */
}

#contactus .contact {
    /* padding: 0 49px; */
    padding: 0px;
}

    #contactus .contact .contact-header {
        margin: 29px 0 34px;
    }

        #contactus .contact .contact-header .text-1 {
            font-size: 30px;
        }

#contactus input {
    height: 45px;
}

#contactus .contact .contact-body .row {
    padding: 0 0 26px;
}

#contactus .contact .contact-body .form-control {
    font-size: 11px;
    font-weight: unset;
    line-height: 14px;
    color: #FFFFFF;
    background-color: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    padding: 16px 24px;
}

#contactus .contact .contact-body .ul-brand {
    padding-left: 0;
    list-style: none;
}

#contactus .contact .contact-body .contact-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5rem;
    margin-bottom: 28px;
    cursor: pointer;
}

    #contactus .contact .contact-body .contact-list li:hover {
        box-shadow: #444 0 0 0px !important;
        border-left: 4px solid #FFC159 !important;
    }

    #contactus .contact .contact-body .contact-list li .channel-logo {
        height: 30px;
        object-fit: contain;
        margin: auto 14px;
    }

.channel-logo {
    height: 30px;
    object-fit: contain;
}

.css-1okebmr-indicatorSeparator {
    display: none;
}

.payment-methods-radio .form-checkbox .option.active {
    background: #FFFFFF1A 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
}

.payment-methods-radio .img2 {
    display: none;
}

.payment-methods-radio .form-checkbox .option .img1 {
    display: none;
}

.payment-methods-radio .form-checkbox .option .img2 {
    display: block;
}

#deposit-section #my-tabs .active {
    background: transparent;
}

#deposit-section #my-tabs .nav-item .nav-link {
    padding: 10px 0;
}

.form-control:focus {
    color: #FFFFFF;
}

.pb-15 {
    padding-bottom: 15px;
}

#footer .select-box .dropdown #dropdown-basic {
    background: transparent linear-gradient(90deg, #113C76 0%, #022556 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 4px 6px #00000029;
    border-radius: 15px;
    width: 200px;
    padding: 12px 0 14px;
}

#footer .select-box .dropdown-menu.show {
    width: 200px;
}

#footer .select-box .dropdown .dropdown-toggle::after {
    content: "";
    display: inline-block;
    margin-left: 36px;
    vertical-align: 3px;
    box-sizing: border-box;
    height: 8px;
    width: 8px;
    border-style: solid;
    border-color: white;
    border-width: 0px 1px 1px 0px;
    transform: rotate(45deg);
}

.thr-titles {
    font-size: 12px !important;
}

#sub-game {
    border-radius: 10px;
}

.contactus-list {
    /* width: 300px; */
    padding: 29px 17px;
    border-radius: 20px;
    background-color: #062B5E;
    height: 100%;
    margin: auto;
    border: 1px #093879 solid;
}

.pregame-popup {
    background: #001A3D;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 51px;
}

    .pregame-popup .wallet-info {
        margin-bottom: 26px;
    }

    .pregame-popup .transfer-balance {
        margin: 20px 0 10px 35px;
        gap: 10px;
    }

.custom-input-style-2 {
    padding: 0px 20px 0px 20px;
    font-size: 12px;
    border-radius: 15px;
    background: #02214a;
    border: 2px solid #fff;
    color: #fff;
    width: 100%;
    height: 40px;
}

.pregame-popup .bonus-commission {
    margin-left: 35px;
    margin-bottom: 30px;
}

.pregame-popup .username-and-password {
    margin-left: 35px;
    margin-bottom: 25px;
}

.pregame-popup .pregame-popup-close-btn {
    position: absolute;
    bottom: -50%;
    left: 50%;
    transform: translateX(-50%);
}

.text-darkblue {
    color: #002e6c;
}

.icon-register {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.license-popup-wrapper {
    height: 100%;
    width: 100%;
    background: black;
    opacity: 0.5;
    position: fixed;
    left: 0;
    top: 0;
}

.license-popup {
    position: fixed;
    align-items: center;
    width: auto;
    height: 75vh;
    background-color: white;
    padding: 15px 10px 10px;
    top: 15vh;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    z-index: 2;
    display: grid;
}

.license-popup-body {
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100%;
    margin-bottom: 10px;
    text-align: center;
}

/*.modal.show .modal-dialog {
    margin: 1.75rem auto;
}*/

#result4d .form-white-search-input .react-datepicker__input-container input {
    background: transparent;
    color: #FFFFFF;
    border: none;
    text-align: center;
}

.livechat-text {
    padding-bottom: 10px;
}

.dropdown-menu {
    transform: translate3d(20px, 50px, 0px) !important;
}

.small-btn.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.sub-category-faq-titles {
    padding: 15px 0;
}

@media (max-width: 991px) {
    #contactus .contact {
        /* padding: 0 20px !important; */
        padding: 0px;
    }

    #downlinetableform .row-data-max {
        width: 55%;
    }

    .contactus-list {
        padding: 16px 14px;
    }

    img.channel-logo {
        height: 26px;
    }
}

@media (max-width: 767px) {
    #result4d .owl-carousel.owl-drag .owl-item-container {
        padding: 0;
        margin: 0;
    }

        #result4d .owl-carousel.owl-drag .owl-item-container .media-object {
            width: 90%;
            height: 90%;
            margin: auto auto 7px;
        }
}

@media (max-width: 540px) {
    #result4d .owl-carousel.owl-drag .owl-item-container .media-object {
        width: 90%;
        height: 90%;
        margin: auto;
    }

    #result4d .owl-carousel.owl-drag .owl-item-container span {
        display: none;
    }
}
